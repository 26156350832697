.quote-banner-picture-wrapper {
    background-color: $lightpurple;

    .quote-banner-picture-bg {
        background-image: url(../img/background/bg-quote-message-2.png);
        background-repeat: no-repeat;
        background-size: cover;
        padding: 120px 0;
        @media #{$vpmobile} {
            padding: 60px 0;
        }
        .section-wrap {
            display: flex;
            max-width: 1260px;
            margin: 0 auto;
            align-items: center;

            @media #{$vpmobile} {
                flex-direction: column;
                max-width: 100%;
            }

            .sec-img {
                padding-right: 55px;
                flex-shrink: 0;
                text-align: center;
                margin: 0;
                @media #{$vpmobile} {
                    padding-right: 0;
                    max-width: 140px;
                    margin: 0 auto;
                    margin-bottom: 8px;
                }
            }
            .sec-txt{
                .heading-txt{
                    padding: 0;
                    @media #{$vpmobile} {
                        padding: 20px 0;                   
                    }
                }
                h3{
                    font-weight: normal;
                    text-align: center;
                    font-size: 28px;
                    /*rtl:raw:line-height: 1.5;*/
                    // padding: 15px 0;
                    color: $white;
                    // font-size: 45px;
                    @media #{$vptabletP} {
                        font-size: 18px;
                    }   
                    @media #{$vpmobile}{
                        // padding: 15px 0;
                    }         
                }
                .sub-para {
                    color: $beige;
                    font-weight: bold;
                    margin: 0;
                    @media #{$vpmobile} {
                        text-align: center;
                    }
                }
            }
            
        }
    }
}