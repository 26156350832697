.news-event {
    background-color: $white;
    position: relative;
    .section-wrap {
        position: relative;

        .section-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            padding: 0 0 49px 0px;
            font-family: 'Effra';

            .heading2{
                margin-bottom: 0;
            }
            h2.sub-heading.magenta-after{
                font-family: 'Effra';
            }
            .cta {
                padding-bottom: 0px;
            }

            &.sty-1 {
                &::after {
                    background-color: $purple;


                    @media #{$lgdesktop} {
                        width: 18px;
                        height: 110px;
                        top: 5px;
                    }

                    @media #{$vpmobile} {
                        width: 6px;
                        height: 131px;
                    }
                }
            }
        }

        &.sty-1 {
            .section-heading {
                padding-left: 0;

                &::after {
                    display: none;
                }
            }
        }

        &.sty-2 {
            .section-heading {
                &::after {
                    background-color: $purple;

                    @media #{$lgdesktop} {
                        width: 18px;
                        height: 110px;
                        top: 5px;
                    }

                    @media #{$vpmobile} {
                        width: 6px;
                        height: 131px;
                    }
                }

            }
        }

        &.sty-3 {
            .section-heading {
                &::after {
                    background-color: $orange;

                    @media #{$lgdesktop} {
                        width: 18px;
                        height: 110px;
                        top: 5px;
                    }

                    @media #{$vpmobile} {
                        width: 6px;
                        height: 131px;
                    }
                }

            }
        }

        &.sty-4 {
            .section-heading {
                &::after {
                    background-color: $green;

                    @media #{$lgdesktop} {
                        width: 18px;
                        height: 110px;
                        top: 5px;
                    }

                    @media #{$vpmobile} {
                        width: 6px;
                        height: 131px;
                    }
                }

            }
        }

        &.sty-5 {
            .section-heading {
                &::after {
                    background-color: $beige;

                    @media #{$lgdesktop} {
                        width: 18px;
                        height: 110px;
                        top: 5px;
                    }

                    @media #{$vpmobile} {
                        width: 6px;
                        height: 131px;
                    }
                }

            }
        }


        .section-slider {

            .card {
                border: none;
                position: relative;
                border-radius: 8px;
                overflow: hidden;
                padding-top: 126%;
                img{
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                    top:0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                &.card--modifier{
                    border-bottom-right-radius: 0px;
                    border-bottom-left-radius: 0px;
                    min-height: auto;
                    .card-body{
                        padding: 15px 15px;
                        .para {
                            margin-bottom: 0;
                        }
                    }
                }

                .card-body {
                    position: absolute;
                    padding: 29px 20px 33px 42px;
                    background-color: rgba(56, 56, 56, 0.75);
                    bottom: 0;
                    width: 100%;

                    @media #{$lgdesktop} {
                        padding: 10px 20px 15px 42px;
                    }

                    @media #{$lgdesktop} {
                        padding: 5px 10px 15px 10px;
                    }

                    @media #{$vpmobile} {
                        padding: 16px 50px 10px 15px;
                    }
                    .para {
                        color: $white;
                    }
                    .para-sm{
                        &.txt-color-beige{
                            font-weight: bold;
                        }
                    }

                    .txt-color-primary {
                        font-weight: bold;
                    }

                    span {
                        padding-right: 20px;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            height: 20px;
                            width: 2px;
                            background-color: $beige;
                            right: 10px;
                            bottom: 0;
                        }
                    }
                }
            }

            .cta {
                @media #{$vptabletP} {
                    padding-top: 50px;
                    text-align: center;
                }
            }
            .col-md-3{
                &:nth-child(2){
                    .card{
                        .card-body{
                            &.orange{
                                &::after{
                                    background-color: #4CB097;
                                }
                            }
                        }
                    }
                }
                &:nth-child(3){
                    .card{
                        .card-body{
                            &.orange{
                                &::after{
                                    background-color: #805997;
                                }
                            }
                        }
                    }
                }
                &:nth-child(4){
                    .card{
                        .card-body{
                            &.orange{
                                &::after{
                                    background-color: #DEC7A6;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.craft-category {
        .section-wrap {
            .section-heading {
                padding-left: 0;

                &::after {
                    display: none;
                }
            }

            .section-slider {
                .card {
                    .card-body {
                        padding: 15px 35px;
                        min-height: 120px;
                        
                        display: flex;
                        align-items: flex-end;

                        @media #{$lgdesktop} {
                            min-height: 90px;
                        }


                        &::after {
                            content: '';
                            position: absolute;
                            height: 100%;
                            width: 18px;
                            background-color: $beige;
                            left: 0;
                            top: 0;
                            border-bottom-left-radius: 8px;
                        }

                        &.orange {
                            &::after {
                                background-color: $orange;
                            }
                        }

                        &.green {
                            &::after {
                                background-color: $green;
                            }
                        }

                        &.purple {
                            &::after {
                                background-color: $purple;
                            }
                        }

                        .sub-title--modifier {
                            font-weight: bold;
                            color: $white;
                            margin-bottom: 0;
                            // @media #{$lgdesktop} {
                            //     margin-bottom: 0;
                            // }

                        }

                    }
                }
            }
        }
    }
}
.card{
    &.card--modifier{
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }
}
.slider-dot-style{
    .slick-dots {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 1rem 0;
        background-color: rgba(160, 158, 158,0.14);
        width: max-content;
        border-radius: 10px;
        height: 9px;
        padding: 0;
        margin: 50px auto 0px auto;
        overflow: hidden;
        list-style-type: none;
        li {
            margin: 0 0;
            button {
                display: block;
                width: 57px;
                height: 9px;
                padding: 0;
                border: none;
                border-radius: 10px;
                background-color: transparent;
                text-indent: -9999px;
                outline: none;
                border: transparent;
                padding: 0;

                &:focus{
                    outline: none;
                    border: transparent;
                }
            }
            &.slick-active button {
                background-color: $magenta;
            }
        }
    }
}