.find-out-wrapper{
    padding: 20px 0;
    h3{
        color: $gray;
        text-align: center;
        margin: 50px 0;
    }   
    p{
       text-align: center; 
    }
    .btn-center{
        display: flex;
        justify-content: center;
        margin: 50px 0;
    }
}