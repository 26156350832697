.secondary-banner-wrapper{
    position: relative;
    margin: 70px 0;   
    .content-wrap {
        position: absolute;        
        top: 25%;
        margin: 0 auto;
        left: 10%;
        padding: 0;
        display: flex;
        align-items: center;        
        @media #{$vptabletL} {
            top: 15%;
        }
        @media #{$vptabletP} {
            padding: 0;
        }
        @media #{$vpmobile} {
            padding: 0;
        }
        .banner-heading {
            .heading1 {
                line-height: 1;
                margin-bottom: 25px;
                font-size: 100px;
                @media #{$vptabletL} {
                    font-size: 75px;
                }
                @media #{$vptabletP} {
                    font-size: 55px;
                }
                @media #{$vpsmmobile} {
                    font-size: 30px;
                }
            }
            .heading6 {
                font-weight: normal;
                margin-bottom: 45px;
                color: $white;
                width: 600px;
                font-size: 35px;
                @media #{$vptabletL} {
                    font-size: 30px;                                
                }
                @media #{$vptabletP} {
                    font-size: 24px;
                    margin-bottom: 30px;                     
                }
                @media #{$vpsmmobile} {
                    width: 100%; 
                }
            } 
        }
    }
}