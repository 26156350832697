.sec-benefits-wrapper {
    background: transparent;
    margin: 0;
    .cpa-wrapper {
        display: flex;
        @media (max-width:991px) {
            flex-direction: column;
        }
        .befefits-img {
            flex: 0 0 31.5%;
            max-width: 31.5%;
            @media (max-width:1199px) {
                flex: 0 0 35%;
                max-width: 35%;
            }
            img {
                width: 100%;
                height: 445px;
                object-fit: cover;
            }
            @media (max-width:991px) {
                flex: 0 0 100%;
                max-width: 100%;
                img {
                    width: 100%;
                }
            }
        }
        .benefits-content-wrapper {
            position: relative;
            display: flex;
            align-items: center;
            flex: 0 0 65%;
            max-width: 65%;
            min-height: 450px;
            @media (max-width:991px) {
                flex: 0 0 100%;
                max-width: 100%;
                align-items: flex-start;
            }
            .cpa-title {
                @media (max-width: 991px) {
                    transform: translate(-11%, -50%);
                }
                .headingVertical {
                    font-size: 126px;
                    line-height: 0.85;
                }
                @media (max-width:767px) {
                    .headingVertical {
                        font-size: 145px;
                    }
                }
            }
            .benefits-detail {
                display: flex;
                padding: 0 40px;
                padding-left: 120px;
                max-width: 100%;
                flex: 0 0 100%;
                @media (max-width:1199px) {
                    padding: 0 10px;
                    padding-left: 120px; 
                }
                @media (max-width:991px) {
                    padding: 60px 10px;
                    padding-left: 130px;
                    flex-direction: column; 
                }
                .post-detail {
                    padding: 0 54px;
                    flex: 0 0 33.33%;
                    max-width: 33.33%;
                    @media (max-width:1500px) {
                        padding: 0 24px;
                    }
                    @media (max-width:1199px) {
                        padding: 0 18px;
                    }
                    @media (max-width:991px) {
                        flex: 0 0 100%;
                        max-width: 100%;
                        margin-top: 40px;
                        &:first-child {
                            margin-top: 0;
                        }
                    }
                    span {
                        color: #EFE8DE;
                        font-size: 60px;
                        font-weight: 700;
                        line-height: 1;
                    }
                    p {
                        color: #CFBFA7;
                        font-size: 18px;
                        font-weight: 700;  
                        line-height: 1.5;
                        @media (max-width: 767px) {
                            font-size: 15px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
}
.promotions-wrapper {
    background-color: #FCFBFA;
    padding: 90px 0;
    @media (max-width:767px) {
        padding: 70px 0;
    }
    .cpd-content {
        .cpd-content-item {
            .sub-para {
                font-weight: 700;
                color: #CFBFA7;
            }
        }
    }
    .js-discovery-slider1-arrows {
        @media (max-width:991px) {
            height: 380px;
            position: absolute;
            width: 100%;
        }
        .slick-arrow {
            bottom: 20px;
            left: 0;
            @media (max-width:991px) {
                margin-bottom: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .cpd-slider-item-wrap {
        .cpdsi-content {
            p {
                color: #969285;
            }
        }
    }
}
.js-discovery-slider1-arrows {
    &.sty1 {
        @media (max-width:991px) {
            height: 320px;
            position: absolute;
            width: 100%;
        }
        @media (max-width:385px) {
            height: 380px;
        }
        .slick-arrow {
            bottom: 20px;
            left: 0;
            @media (max-width:991px) {
                bottom: 0;
                margin-bottom: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}
.howToRegister-wrapper {
    .register-title {
        margin-bottom: 60px;
        @media (max-width: 767px) {
            margin-bottom: 115px;
        }
    }
    .promotion-boxes {
        background: #721C34;
        padding: 60px 40px;
        text-align: center;
        margin: 0 7px;
        border-radius: 9px;
        height: auto;
        @media (max-width:767px) {
            padding: 30px 40px;
        }
        .heading3 {
            font-size: 24px;
            color: #CFBFA7;
            font-weight: 700;
            margin: 10px 0;
        }
        .sub-para {
            font-size: 15px;
            color: #EFE8DE;
        }
    }
    .slick-list {
        padding-right: 100px; 
        @media (max-width:767px) {
            padding-right: 50px;
        }
        .slick-track {
            margin-left: -7px;
            display: flex;
        }
    }
    .slick-arrow {
        text-indent: -9999px;
        top: -85px;
        right: 100px;
        z-index: 1;
        width: 99px;
        height: 28px;
        background-size: contain;
        @media (max-width:991px) {
            background-size: contain;
            right: 50px;
            width: 56px;
        }
        @media (max-width:767px) {
            top: -70px;
            right: auto;
            left: 0;
        }
        &.slick-prev {
            background-image: url(../img/icon/arrow-back.svg);
            /*rtl:raw:background-image: url(../img/icon/arrow-forward.svg)*/
            right: 180px;
            @media (max-width:991px) {
                right: 150px;
            }
            @media (max-width:767px) {
                right: auto;
                left: 0;
            }
        }
        &.slick-next {
            background-image: url(../img/icon/arrow-forward.svg);
             /*rtl:raw:background-image: url(../img/icon/arrow-back.svg)*/
            @media (max-width:767px) {
                left: 85px;
            }
        }
        &:focus {
            outline: none;
        }
    }
    .sec-btn {
        text-align: center;
        margin-top: 90px;
        @media (max-width:767px) {
            margin-top: 70px;
        }
        .btn-lg {
            text-align: center;
            min-width: 274px;
            @media (max-width:767px) {
                font-size: 15px;
                font-weight: 400;
            }
        }
    }
}
.sec-partner {
    .our-partner {
        .section-wrap {
            align-items: flex-start;
            flex-direction: column;
            .logo-blog {
                margin-top: 42px;
                @media (max-width:767px) {
                    margin-top: 0;
                }
                .logo-item {
                    max-width: 100%;
                    display: flex;
                    align-items: center;
                    @media (max-width:1199px) {
                        justify-content: space-between;
                    }
                    @media (max-width:767px) {
                        flex-direction: column;
                    }
                    li {
                        padding: 0 70px;
                        text-align: left;
                        &:first-child {
                            padding-left: 0;
                        }
                        @media (max-width:1199px) {
                            padding: 0;
                        }
                        @media (max-width:767px) {
                            padding:28px 0;
                        }
                    }
                }
            }
        }
    }
}