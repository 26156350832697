.join-us{
    background-color: $beige;
    padding: 120px 0 120px 0;
    position: relative;
    overflow-y: hidden;
    @media #{$vptabletP}{
        padding: 60px 0px 60px 0px;
        display: block;
    }
    .form-group{
        position: relative;
    }
     .pattren-img{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        opacity: 0.10;
        cursor: none;
        z-index: 0;
        /*rtl:raw:transform: scale(-1)*/
        img{
            object-fit: cover;
            height: 100%;
        }
     }
    .join-us-wrapper{
        display: flex;
        justify-content: space-between;
        @media #{$vpmobile}{
    
         }
         @media #{$vptabletPro} {
            flex-direction: column;
        }
        @media #{$vptabletPro}{
            max-width: 599px;
            margin: 0 auto;
        }
        .join-us-title{
            flex: 0 0 30.6%;
            max-width: 30.6%;
            width: 100%;
            z-index: 1;
            //1600
            @media #{$lgdesktop} {
                flex: 0 0 42.6%;
                max-width: 42.6%;
            }
            @media #{$vptabletPro1} {
                flex: 0 0 30.6%;
                max-width: 30.6%;
            }
            @media #{$vptabletPro} {
                flex: 0 0 100%;
                max-width: 100%;
            }
            .heading2{
                color: $white;
                display: flex;
                flex-direction: column;
                @media #{$vptabletPro} {
                    padding-left: 0px;
                    text-align: center;
                    width: 100%;
                }
                span {
                    font-size: 16px;
                }
            }
        }
        .join-us-content{
            padding-left: 121px;
            padding-top: 0px;
            @media #{$vpSmdesktop}{
                padding-left: 50px;
           
            }
            @media #{$vptabletPro} {
                padding-left: 0px;
                text-align: center;
            }
            @media #{$vptabletP}{
                padding-left: 0px;
             }
            @media #{$vpmobile}{
               
             }
            .join-us-content-wrapper{
                max-width: 580px;
                width: 100%; 
                p{
                    color: $white;
                    margin-top: 0;
                }
                .mce-responses{
                    position: absolute;
                    bottom: -37px;
                    left:0;
                    width: 100%;
                }
                .response {
                    /*rtl:begin:ignore*/
                        margin-top: 10px;
                        text-align: left;
                        font-family: 'Effra';
                        direction: ltr;
                        font-size: 15px;
                        color: #fff;
                    /*rtl:end:ignore*/
                    a{
                      font-size: inherit;
                      color: inherit;  
                    }

                }
                .error-message{
                    margin-top: 10px;
                    color: $errrorClr;
                    display: none;
                    position: absolute;
                    bottom: -33px;
                    left: 5px;
                }
                .mce_inline_error + .error-message ,.mce_inline_error ~ .error-message{
                    display: block;
                }
                .join-us-form{
                    margin-top: 20px;
                    max-width: 580px;
                    width: 100%;
                    position: relative;
                    @media #{$vptabletPro} {
                        max-width: 100%;
                     }
                     .invalid-field{
                        .form-control{
                            border-color: $errrorClr;
                            &:focus{
                                border-color: $errrorClr;
                                box-shadow: none;
                            }
                        }
                        .join-us-btn{
                            // border-color: $errrorClr;
                        }

                     }
                    .form{
                        width: 100%;
                    }
                    .form-control{
                        height: 46px;
                        padding-left: 36px;
                        padding-right: 175px;
                        border-radius: 8px;
                        font-size: 20px;
                        text-align: left;
                        border: 2px solid $white;
                        color: $black;
                        font-family: 'Effra'  !important;
                        background-color: $white;
                        @media #{$vptabletPro} {
                        //    font-size: 21px;
                           padding: 0 15px;
                           text-align: center;
                        }
                        &::placeholder{
                            color: $placehoderClr;
                        }
                        &:focus{
                            box-shadow: none;
                        }
                        &.mce_inline_error{
                            border-color: $errrorClr;
                        }
                    }
                    .join-us-btn{
                        position: absolute;
                        top: 0;
                        right: 0;  
                        border-radius: 8px;
                        z-index: 1;
                        padding: 9px 0;
                        /*rtl:raw:padding: 13px 0 5px 0;*/
                        @media #{$vptabletPro} {
                            position: relative;
                            margin-top: 20px;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}