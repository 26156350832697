.sec-category {
    background-color: $vistawhite;
    .category-wrap {
        .sec-img {
            border-radius: 8px;
            overflow: hidden;
            @media #{$vptabletP} {
                display: none;
            }
            img {
                max-width: 938px;
            }
        }
        .aside {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 110px 0 65px;
            @media #{$lgdesktop} {
                padding: 0 0 0 30px;
            }
            @media #{$vptabletP} {
                padding: 0;
                margin-top: 10px;
            }
                .card-body-content{
                    margin: 0;
                    .para {
                        margin: 20px 0 40px;
                        @media #{$vpmobile} {
                            margin: 20px 0 20px;
                        }
                    }
                    @media #{$vpmobile} {
                        padding-left: 0;
                    }
                    .cta {
                        .btn-lg {
                            font-weight: bold;
                        }
                    }
                }
        }
        
    }
    &.flip {
        .aside {
            padding: 0 100px 0 0px;
            @media #{$vptabletP} {
                padding: 0;
            }
        }
    }
}