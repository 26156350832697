html {
    @include primaryfontFamily;
    font-weight: normal;
    color: $iBlack;
    background: $white;
    font-weight: 300;
    height: 100%;
    line-height: 1.4;
    background-color: #fafbfe;
    direction: ltr;
    width: 100%;
    &.browserIos {
        overflow-x: hidden;
    }
}

body {
    min-height: 100vh;
    width: 100%;
    font-size: 16px;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    @include primaryfontFamily;
    &.js-hiden-body{
        overflow-y: hidden;
    }
}
.js-hiden-body {
    overflow: hidden;
}
@media (min-width: 1600px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl{
        max-width: 1575px;
    }
}
@media (min-width: 1600px) {
    .container{
        &.container-extended {
            max-width: 1740px;
        }
    }
}
@media #{$vpmobile} {
    .container{
        padding-left: 20px;
        padding-right: 20px;
    }
}
::-moz-selection {
    background: $beige;
    text-shadow: none;
}

::selection {
    background: $beige;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

*,
*:after,
*:before {
    outline: none;
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none !important;
    outline: none !important;
    color: $iMagenta;
    &:hover {
        color: $iBlack;
    }
}

p {
    line-height: 1.6;
    margin: 10px 0;
    font-size: 15px;
    i {
        font-style: italic;
    }
    @media #{$vpmobile} {
        font-size: 14px;
    }
    @media (max-width:575px) {
        font-size: 13px;
    }
}

svg {
    transform: translateZ(0);
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: none;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
}

.clearfix:after {
    clear: both;
}

.o-hidden {
    overflow: hidden;
    height: 100%;
}

.u-hide {
    display: none !important;
}

.dnone {
    display: none!important;
}

.u-pos-s {
    position: static;
}

.u-pos-r {
    position: relative;
}

.pl {
    &-20 {
        padding-left: 20px;
    }
    &-25 {
        padding-left: 25px;
    }
    &-40 {
        padding-left: 40px;
    }
    &-50 {
        padding-left: 50px;
    }
}

.pr {
    &-20 {
        padding-right: 20px;
    }
    &-25 {
        padding-right: 25px;
    }
    &-40 {
        padding-right: 40px;
    }
    &-50 {
        padding-right: 50px;
    }
}

.pt {
    &-20 {
        padding-top: 20px;
    }
    &-25 {
        padding-top: 25px;
    }
    &-40 {
        padding-top: 40px;
    }
    &-50 {
        padding-top: 50px;
    }
}

.pb {
    &-20 {
        padding-bottom: 20px;
    }
    &-25 {
        padding-bottom: 25px;
    }
    &-40 {
        padding-bottom: 40px;
    }
    &-50 {
        padding-bottom: 50px;
    }
}

.ml {
    &-20 {
        margin-left: 20px;
    }
    &-25 {
        margin-left: 25px;
    }
    &-40 {
        margin-left: 40px;
    }
    &-50 {
        margin-left: 50px;
    }
}

.mr {
    &-20 {
        margin-right: 20px;
    }
    &-25 {
        margin-right: 25px;
    }
    &-40 {
        margin-right: 40px;
    }
    &-50 {
        margin-right: 50px;
    }
}

.mt {
    &-20 {
        margin-top: 20px;
    }
    &-25 {
        margin-top: 25px;
    }
    &-40 {
        margin-top: 40px;
    }
    &-50 {
        margin-top: 50px;
    }
}

.mb {
    &-20 {
        margin-bottom: 20px;
    }
    &-25 {
        margin-bottom: 25px;
    }
    &-40 {
        margin-bottom: 40px;
    }
    &-50 {
        margin-bottom: 50px;
    }
}

.toSvg {
    max-width: 100%;
    max-height: 100%;
    polygon,
    path {
        transition: fill 0.35s linear;
    }
}

input::-ms-clear {
    display: none;
}

.primaryfont {
    @include primaryfontFamily;
}

.for--mobilehide {
    @media #{$vpmobile} {
        display: none!important;
    }
}

.for--mobileshow {
    @media(min-width: 768px) {
        display: none!important;
    }
}

.for--tablethide {
    @media #{$vptabletP} {
        display: none!important;
    }
}

.for--tabletshow {
    @media(min-width: 992px) {
        display: none!important;
    }
}

.grayScale {
    >img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: 0.6;
        transition: all 0.5s ease;
    }
    &:hover {
        >img {
            -webkit-filter: none;
            filter: none;
            opacity: 1;
        }
    }
    @media #{$vptabletP} {
        >img {
            -webkit-filter: none;
            filter: none;
            opacity: 1;
        }
    }
}

.bdCenter {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: 0 auto;
        width: 1px;
        height: 100%;
        background-color: #e3e4e7;
    }
}

.nopdlr--mobile {
    @media #{$vpmobile} {
        padding-left: 0;
        padding-right: 0;
    }
}

[class^="box-"] {
    display: none;
    &.showfirst {
        display: block;
    }
}

.gridView {
    >ul {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 -15px;
        flex-wrap: wrap;
        >li {
            padding: 15px;
            flex: 0 0 25%;
            .programmeExperience {
                width: 100%;
            }
            .IE & {
                flex: 0 0 23%;
            }
        }
    }
    @media (max-width:1550px) {
        >ul {
            >li {
                flex: 0 0 33.33%;
            }
        }
    }
    @media (max-width:1275px) {
        >ul {
            >li {
                flex: 0 0 50%;
            }
        }
    }
    @media #{$vptabletP} {
        >ul {
            >li {
                flex: 0 0 50%;
            }
        }
    }
    @media #{$vpmobile} {
        >ul {
            >li {
                flex: 0 0 100%;
            }
        }
    }
}

.IE {
    .gridView {
        >ul {
            >li {
                flex: 0 0 23%;
            }
        }
    }
}

.popup-is-active {
    overflow: hidden;
}

.loadingDv {
    text-align: center;
    padding: 50px 0 25px 0;
    position: relative;
    min-height: 134px;
    span {
        font-size: 9px;
        font-weight: 300;
        text-transform: uppercase;
        color: $iBlack;
        margin-top: 7px;
        display: block;
    }
    >span {
        cursor: pointer;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 106px;
        transition: all 0.5s ease;
        height: 30px;
    }
    .loadmorebtn {
        display: none;
        >img {
            width: 49px;
        }
    }
}

.dropdown-wrap {
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: #fff;
        border-radius: 0 0 5px 5px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-top: none;
        border-bottom: none;
        list-style: none;
        transition: all 0.3s ease-out;
        max-height: 0;
        overflow: hidden;
        z-index: 4;
        min-width: 207px;
        li {
            padding: 0 10px;
            display: block;
            margin: 0;
            a {
                display: block;
                text-decoration: none;
                color: $iBlack;
                padding: 10px 0;
                transition: all 0.3s ease-out;
                border-bottom: 1px solid #e6e8ea;
                font-size: 12px;
            }
            &:last-of-type a {
                border: none;
            }
            &:hover a {
                color: $iMagenta;
            }
        }
    }
    &.is--active {
        .filterBtn {
            background-color: $iMagenta;
        }
        .dropdown {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            max-height: 400px;
        }
    }
}

.row {
    &.pd_10 {
        margin-left: -10px;
        margin-right: -10px;
        [class*=col-] {
            padding: 0 10px;
        }
    }
}

.locationBg {
    position: relative;
    @media #{$vpmobile} {
        min-height: 610px;
    }
}

.bgImg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    transition: all 0.5s ease-in-out;
    top: 0;
    left: 0;
    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        opacity: 1;
        transition: all 0.5s ease-in-out;
        background: -webkit-repeating-linear-gradient(145deg, rgba(64, 64, 64, .5), rgba(64, 64, 64, .5) 2px, hsla(0, 0%, 100%, .1) 0, hsla(0, 0%, 100%, .1) 7px);
        background: -o-repeating-linear-gradient(145deg, rgba(64, 64, 64, .5), rgba(64, 64, 64, .5) 2px, hsla(0, 0%, 100%, .1) 0, hsla(0, 0%, 100%, .1) 7px);
        background: repeating-linear-gradient(-55deg, rgba(64, 64, 64, .5), rgba(64, 64, 64, .5) 2px, hsla(0, 0%, 100%, .1) 0, hsla(0, 0%, 100%, .1) 7px);
    }
    .bg1 {
        &:before,
        &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            transition: all 0.5s ease-in-out;
        }
        &:before {
            z-index: 2;
            background-color: #242424;
            opacity: .97;
        }
        &:after {
            z-index: 3;
            background: #6b89b7;
            background: -webkit-linear-gradient(320deg, #6b89b7 40%, #ba287f);
            background: -o-linear-gradient(320deg, #6b89b7 40%, #ba287f);
            background: linear-gradient(130deg, #6b89b7 40%, #ba287f);
            opacity: .5;
        }
    }
    &.style1 {
        z-index: 1;
        &:before {
            background: -webkit-repeating-linear-gradient(145deg, rgba(144, 144, 144, .5), rgba(144, 144, 144, .5) 2px, hsla(0, 0%, 100%, .1) 0, hsla(0, 0%, 100%, .1) 7px);
            background: -o-repeating-linear-gradient(145deg, rgba(144, 144, 144, .5), rgba(144, 144, 144, .5) 2px, hsla(0, 0%, 100%, .1) 0, hsla(0, 0%, 100%, .1) 7px);
            background: repeating-linear-gradient(-55deg, rgba(144, 144, 144, .1), rgba(144, 144, 144, .1) 2px, hsla(0, 0%, 100%, 0.05) 0, hsla(0, 0%, 100%, 0.05) 3.4px);
        }
    }
}

.mouseanimateDv {
    display: inline-block;
    text-align: center;
    padding: 20px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 1.7s ease-in-out;
    opacity: 0;
    z-index: 1;
    visibility: hidden;
    .toSvg {
        width: 28px;
        height: 47px;
        display: inline-block;
        transition: all 1s ease-in-out;
        margin-bottom: 13px;
        position: absolute;
        left: 50%;
        top: -100%;
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, -50%);
        .anim-path1,
        .anim-path2,
        .anim-path3,
        .anim-path4 {
            animation: mouseanimate 1s infinite;
        }
        .anim-path1,
        .anim-path3 {
            animation-delay: .1s;
            animation-direction: alternate;
        }
        .anim-path2,
        .anim-path4 {
            opacity: 0;
            animation-delay: .3s;
            animation-direction: alternate;
        }
        &.touchIcon {
            display: none;
        }
        @media #{$vptabletP} {
            &.touchIcon {
                display: inline-block;
            }
            &.mouseIcon {
                display: none;
            }
        }
    }
    .blurFont {
        opacity: 0;
        transition: all 1s ease-in-out;
        visibility: hidden;
    }
    span {
        padding-top: 11px;
        display: block;
        line-height: 9px;
        text-transform: uppercase;
        color: $white;
        font-weight: 300;
        font-size: 12px;
        transition: all 1s ease-in-out;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        &.slideMRight {
            top: calc( 50% + 47px);
            transform: translate(50%, -50%);
        }
        &.slideMLeft {
            top: calc( 50% + 67px);
            transform: translate(-50%, -50%);
        }
    }
    .bgImg {
        opacity: 0;
        transition: all 1.5s ease-in-out;
        visibility: hidden;
    }
    .slideMTop {
        top: -100%;
        opacity: 0;
        visibility: hidden;
    }
    .slideMLeft {
        left: -100%;
    }
    .slideMRight {
        right: -100%;
    }
    &.showMAnimate {
        opacity: 1;
        visibility: visible;
        .bgImg {
            opacity: 1;
            visibility: visible;
        }
        .slideMTop {
            top: 50%;
            opacity: 1;
            visibility: visible;
        }
        .blurFont {
            opacity: 0.04;
            visibility: visible;
            .IE & {
                opacity: 0.02;
            }
        }
        .slideMLeft {
            left: 50%;
            opacity: 1;
            visibility: visible;
        }
        .slideMRight {
            right: 50%;
            opacity: 1;
            visibility: visible;
        }
    }
}

@-webkit-keyframes mouseanimate {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes mouseanimate {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes mouseanimate {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes mouseanimate {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

.plyr--full-ui {
    input[type=range] {
        color: $white;
    }
    .plyr__controls {
        .plyr__menu,
        .plyr__time {
            display: none;
        }
        .plyr__control {
            width: 42px;
            height: 34px;
            padding: 0;
            // svg.icon--not-pressed {
            //     use {
            //         opacity: 0;
            //     }
            // }
            &[data-plyr="play"] {
                svg {
                    height: 20px;
                    width: 17px;
                    margin: auto;
                    use {
                        opacity: 0;
                    }
                }
                svg.icon--pressed {
                    background: url(../img/icons/ic-pause.svg) no-repeat 0 0 scroll;
                }
                svg.icon--not-pressed {
                    background: url(../img/icons/ic-play.svg) no-repeat 0 0 scroll;
                    width: 20px;
                }
            }
            &[data-plyr="fullscreen"] {
                margin-left: 10px;
                svg {
                    margin: auto;
                }
                svg.icon--not-pressed {
                    background: url(../img/icons/ic-fullscreen.svg) no-repeat 0 0 scroll;
                    height: 26px;
                    width: 34px;
                    use {
                        opacity: 0;
                    }
                }
            }
            &[data-plyr="mute"] {
                svg {
                    height: 26px;
                    width: 25px;
                    margin: auto;
                    use {
                        opacity: 0;
                    }
                }
                svg.icon--pressed {
                    background: url(../img/icons/ic-mute.svg) no-repeat 0 0 scroll;
                }
                svg.icon--not-pressed {
                    background: url(../img/icons/ic-volume.svg) no-repeat 0 0 scroll;
                }
            }
        }
        .plyr__volume {
            margin-left: 10px;
        }
    }
}

.plyr--video {
    .plyr__controls {
        .plyr__control {
            &.plyr__tab-focus,
            &:hover,
            &[aria-expanded=true] {
                background: $iGreen;
            }
        }
    }
}

.plyr__control--overlaid {
    background: $iGreen;
    &:hover,
    &:focus {
        background: $iGreen;
    }
}

.css-scrollable {
    .mCSB_scrollTools {
        .mCSB_draggerRail {
            background-color: rgba(0, 0, 0, .2);
        }
        .mCSB_dragger {
            .mCSB_dragger_bar {
                background-color: rgba(60, 63, 64, 0.4);
            }
        }
    }
}

.sample-box {
    width: 200px;
    height: 200px;
}
.bg-color{
    &-primary{
        background-color: $magenta;
    }
    &-primary-light{
        background-color: $lightmagenta;
    }
    &-secondary {
        background-color: $gray;
    }
    &-secondary-light {
        background-color: $lightgray;
    }
    &-beige {
        background-color: $beige;
    }
    &-lightbeige {
        background-color: $lightbeige;
    }
    &-orange {
        background-color: $orange;
    }
    &-lightorange {
        background-color: $lightorange;
    }
    &-green {
        background-color: $green;
    }
    &-lightgreen {
        background-color: $lightgreen;
    }
    &-purple {
        background-color: $purple;
    }
    &-lightpurple {
        background-color: $lightpurple;
    }
    &-gray-3 {
        background-color: $grayssty3;
    }
    &-gray-4 {
        background-color: $grayssty4;
    }
    &-gray-5 {
        background-color: $grayssty5;
    }
}
.txt-color{
    &-primary{
        color: $magenta;
    }
    &-primary-light{
        color: $lightmagenta;
    }
    &-secondary {
        color: $gray;
    }
    &-secondary-light {
        color: $lightgray;
    }
    &-beige {
        color: $beige;
    }
    &-lightbeige {
        color: $lightbeige;
    }
    &-orange {
        color: $orange;
    }
    &-lightorange {
        color: $lightorange;
    }
    &-green {
        color: $green;
    }
    &-lightgreen {
        color: $lightgreen;
    }
    &-purple {
        color: $purple;
    }
    &-lightpurple {
        color: $lightpurple;
    }
    &-gray-3 {
        color: $grayssty3;
    }
    &-gray-4 {
        color: $grayssty4;
    }
    &-gray-5 {
        color: $grayssty5;
    }
}
.svg-fill-primary {
    path,
    polygon,
    rect{
        fill: $magenta;
    }
}
.svg-fill-primary-light {
    path,
    polygon,
    rect{
        fill: $lightmagenta;
    }
}
.svg-fill-secondary {
    path,
    polygon,
    rect{
        fill: $gray;
    }
}
.svg-fill-secondary-light {
    path,
    polygon,
    rect{
        fill: $lightgray;
    }
}
.svg-fill-beige {
    path,
    polygon,
    rect{
        fill: $beige;
    }
}
.svg-fill-lightbeige {
    path,
    polygon,
    rect{
        fill: $lightbeige;
    }
}
.svg-fill-orange {
    path,
    polygon,
    rect{
        fill: $orange;
    }
}
.svg-fill-lightorange {
    path,
    polygon,
    rect{
        fill: $lightorange;
    }
}
.svg-fill-green {
    path,
    polygon,
    rect{
        fill: $green;
    }
}
.svg-fill-lightgreen {
    path,
    polygon,
    rect{
        fill: $lightgreen;
    }
}
.svg-fill-purple {
    path,
    polygon,
    rect{
        fill: $purple;
    }
}
.svg-fill-lightpurple {
    path,
    polygon,
    rect{
        fill: $lightpurple;
    }
}
.btn{
    font-size: 16px;
    color: $white;
    /*rtl:remove*/
    padding: 11px 23px;
    /*rtl:remove*/
    /*rtl:raw:padding: 14px 23px 8px 23px;*/
    border-radius: 6px;
    text-align: left;
    position: relative;
    transition: all 0.25s ease-in-out;
    font-weight: bold;
    &.btn{
        &-magenta{
            background-color: $magenta;
            border: 2px solid $magenta;
            svg {
                path,
                rect,
                polygon,
                line {
                    stroke: $white;
                }
            }
            &:hover{
                background-color: $iPink;
                border-color: $iPink;
                color: $white;
                svg {
                    path,
                    rect,
                    polygon,
                    line {
                        stroke: $white;
                    }
                }
            }
            &-out-line{
                border: 2px solid $magenta;
                    color: $magenta;
                    svg {
                        path,
                        rect,
                        polygon,
                        line {
                            stroke: $magenta;
                        }
                    }
                    &:hover{
                        background-color: $magenta;
                        color: $white;
                        svg {
                            path,
                            rect,
                            polygon,
                            line {
                                stroke: $white;
                            }
                        }
                    }
            }
        }
        &-beige{
            background-color: $beige;
            border: 2px solid $beige;
            svg {
                path,
                rect,
                polygon,
                line {
                    stroke: $white;
                }
            }
            &:hover{
                background-color: transparent;
                color: $beige;
                svg {
                    path,
                    rect,
                    polygon,
                    line {
                        stroke: $beige;
                    }
                }
            }
            &-out-line{
                border: 2px solid $beige;
                    color: $beige;
                    svg {
                        path,
                        rect,
                        polygon,
                        line {
                            stroke: $beige;
                        }
                    }
                    &:hover{
                        background-color: $beige;
                        color: $white;
                        svg {
                            path,
                            rect,
                            polygon,
                            line {
                                stroke: $white;
                            }
                        }
                    }
            }
        }
        &-lightgray{
            background-color: $lightgray;
            border: 2px solid $lightgray;
            svg {
                path,
                rect,
                polygon,
                line {
                    stroke: $white;
                }
            }
            &:hover{
                background-color: transparent;
                color: $lightgray;
                svg {
                    path,
                    rect,
                    polygon,
                    line {
                        stroke: $lightgray;
                    }
                }
            }
            &-out-line{
                border: 2px solid $lightgray;
                    color: $lightgray;
                    svg {
                        path,
                        rect,
                        polygon,
                        line {
                            stroke: $lightgray;
                        }
                    }
                    &:hover{
                        background-color: $lightgray;
                        color: $white;
                        svg {
                            path,
                            rect,
                            polygon,
                            line {
                                stroke: $white;
                            }
                        }
                    }
            }
        }
        &-gray{
            background-color: $gray;
            border: 2px solid $gray;
            svg {
                path,
                rect,
                polygon,
                line {
                    stroke: $white;
                }
            }
            &:hover{
                background-color: transparent;
                color: $gray;
                svg {
                    path,
                    rect,
                    polygon,
                    line {
                        stroke: $gray;
                    }
                }
            }
            &-out-line{
                border: 2px solid $gray;
                    color: $gray;
                    svg {
                        path,
                        rect,
                        polygon,
                        line {
                            stroke: $gray;
                        }
                    }
                    &:hover{
                        background-color: $gray;
                        color: $white;
                        svg {
                            path,
                            rect,
                            polygon,
                            line {
                                stroke: $white;
                            }
                        }
                    }
            }
        }
    }
    
    &.btn{
        &-xl{
            min-width: 240px;
        }
        &-lg{
            min-width: 240px;
            @media #{$vptabletP}{
                min-width: 233px;
            }
        }
        &-sm{
            min-width: 165px;
            text-align: center;
        }
    }
    &:focus{
        box-shadow: none;
    }
    .ic-arrow{
        position: absolute;
        top: 14px;
        right: 20px;
        width: 18px;
        height: 18px;
        /*rtl:raw:transform: scale(-1)*/
    }
}
// .sub-heading {
// 	padding-left: 55px;
// 	position: relative;
// 	@media #{$vptabletL} {
// 		padding-left: 45px;
// 	}
// 	@media #{$vpmobile} {
// 		padding-left: 20px;
// 	}

// 	&::after {
// 		content: "";
// 		position: absolute;
// 		width: 18px;
// 		top: 50%;
// 		left: 0;
// 		height: 100%;
// 		max-height: 190px;
// 		transform: translateY(-50%);

// 		@media #{$vpmobile} {
// 			width: 6px;
// 			max-height: 131px;
// 		}
// 	}
// 	&.magenta{
// 		&::after {
// 			background-color: $magenta;
// 		}
// 	}
// 	&.beige{
// 		&::after {
// 			background-color: $beige;
// 		}
// 	}
// 	&.purple{
// 		&::after {
// 			background-color: $purple;
// 		}
// 	}
// }
.gutter-40{
    padding: 0 20px;
}
.row-gutter-40{
    margin: 0 -20px;
}
.section-padding{
    padding: 60px 0;
    @media #{$vptabletP} {
        padding: 40px 0;
    }
    @media #{$vpmobile} {
        padding: 30px 0;
    }
    &-top{
        padding: 60px 0 0;
        @media #{$vptabletP} {
            padding: 40px 0 0;
        }
        @media #{$vpmobile} {
            padding: 30px 0 0;
        }
    }
    &-bottom{
        padding: 0 0 60px;
        @media #{$vptabletP} {
            padding: 0 0 40px;
        }
        @media #{$vpmobile} {
            padding: 0 0 30px;
        }
    }
}
// .section-padding-bt-40{
//     padding: 60px 0 20px 0;
//     @media #{$vptabletP} {
//         padding: 40px 0 20px 0;
//     }
//     @media #{$vpmobile} {
//         padding: 30px 0 10px 0;
//     }

// }
.section-padding-60{
    padding: 60px 0;
    @media #{$vptabletP} {
        padding: 45px 0;
    }
    @media #{$vpmobile} {
        padding: 30px 0;
    }
    &-top{
        padding: 60px 0 0;
        @media #{$vptabletP} {
            padding: 45px 0 0;
        }
        @media #{$vpmobile} {
            padding: 30px 0 0;
        }
    }
    &-bottom{
        padding: 0 0 60px;
        @media #{$vptabletP} {
            padding: 0 0 45px;
        }
        @media #{$vpmobile} {
            padding: 0 0 30px;
        }
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.js-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1003;
    background: rgba(#DDC7A6, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    direction: ltr;
    > div {
        display: block;
        position: relative;
        left: 0;
        top: 0;
        width: 150px;
        height: 150px;
        margin: 0;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: $white;
        transform: translate3d(0, 0, 0);
        animation: spin 2s linear infinite;
        background-image: url("~/assetsimg/icons/ic-loading.gif");
        &::before {
            content: "";
            position: absolute;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            border-radius: 50%;
            border: 3px solid transparent;
            border-top-color: #ffffff;
            -webkit-animation: spin 3s linear infinite;
            animation: spin 3s linear infinite;
        }
        &::after {
            content: "";
            position: absolute;
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 15px;
            border-radius: 50%;
            border: 3px solid transparent;
            border-top-color: $white;
            animation: spin 1.5s linear infinite;
        }
    }
}
.terms-condition-content {
    .content-wrap {
        max-width: 1110px;
        width: 100%;
        margin: 0 auto 20px auto;
        @media #{$vpmobile} {
           margin: 0; 
        }
        .heading2 {
            margin: 30px 0;
            font-weight: bold;
            @media #{$vpmobile} {
                margin: 15px 0; 
             }
        }
        .heading3{
            font-weight: bold;
        }
        a {
            text-decoration: none;
            color: $magenta;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                width: 0;
                height: 1px;
                background: $magenta;
                left: 0;
                bottom: 0;
                transition: all 0.35s ease;
            }
            &:hover {
                &:after {
                    width: 100%;
                }
            }
        }
    }
}
.ar-font{
    font-family: 'Harir';
}
.en-font{
    font-family: 'Effra';
}
.tintup {
    height: 500px !important;
}
.spnUniqueIdStyling{
    font-family: 'Effra';
}
.slick-arrow {
    position: absolute;
    bottom: 0px;
    background-repeat: no-repeat;
    text-indent: 0;
    background-color: transparent;
    border: none;
    outline: none;
    z-index: 1;
    @media #{$vpmobile} {
        padding: 0;
    }
    img,svg{
        height: 30px;
        width: auto;
        @media #{$vpmobile} {
            height: 30px;
            width: 30px;
        }
    }

    @media #{$vpdesktop} {
    }

    @media #{$vptabletL} {
    }

    // @media #{$vptabletP} {
    //     width: 44px;
    // height: 44px;
    // left: calc(50% - 47px);
    // bottom: -85px;

    // }
    @media #{$vptabletP} {
        width: 45px;
        height: 45px;
        bottom: 0px;
    }
}

.slick-next {


    @media #{$vpdesktop} {
    }

    @media #{$vptabletL} {
    }
    @media #{$vptabletP} {
    }
    @media #{$vpmobile} {
        padding: 0;
    }
    // @media #{$vpmobile} {
    //     left: calc(50% - -17px);
        
    // }

    &:hover,
    &:focus {
       .toSvg {
        path{
            fill: rgba(56,56,56,1);
        }
       }
    }
}

.slick-prev {
   

    &:hover,
    &:focus {
        .toSvg {
            path{
                fill: rgba(56,56,56,1);
            }
           } 
    }
}
.paddingLeftRight {
    padding-left: 60px;
    padding-right: 60px;
    @media #{$vpdesktop} {
        // padding: 35px 36px;
        padding-left: 36px;
        padding-right: 36px;
    }
    // max-width 991px
    @media #{$vptabletP} {
        // padding: 20px 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
    @media #{$vpmobile} {
        // padding: 20px 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
.paddingLeft {
    padding-left: 60px;
    @media #{$vpdesktop} {
        // padding: 35px 36px;
        padding-left: 36px;
    }
    // max-width 991px
    @media #{$vptabletP} {
        // padding: 20px 20px;
        padding-left: 20px;
    }
    @media #{$vpmobile} {
        // padding: 20px 20px;
        padding-left: 20px;
    }
}
.container--left{
    margin-left: calc((100vw - 1575px) / 2);
    padding-left: 15px;
    @media (max-width: 1600px) {
        margin-left: calc((100vw - 1140px) / 2);
    }
    @media (max-width: 1200px) {
        margin-left: calc((100vw - 960px) / 2);
    }
    @media (max-width: 992px) {
        margin-left: calc((100vw - 720px) / 2);
    }
    @media (max-width: 768px) {
        margin-left: calc((100vw - 540px) / 2);
    }
    @media (max-width: 576px) {
        margin-left: calc((100vw - 100%) / 2);
    }
}

.section-paddingTopBottom120{
    padding-top: 120px;
    padding-bottom: 120px;
    @media #{$vptabletP} {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media #{$vpmobile} {
        padding-top: 83px;
        padding-bottom: 83px;
    }
}
.text-shadow {
    text-shadow: 2px 2px #000000;
}
.gallery-sec .gs-wrapper .slick-dots li {
    font-family: 'Effra' !important;
    /*rtl:raw:font-family: 'Effra' !important;*/
}