.faq {
    background-color: $lightbeige;
    &.faq-white-clr{
        background-color: $white;
        .accordion{
            max-width: 1110px;
            width: 100%;
            margin: 0 auto 0px auto;
        }
    }
    .sec-heading {
        padding-bottom: 20px;
        @media #{vpmobile} {
            padding-bottom: 20px;
        }
        .heading2 {
            text-align: center;
        }
    }
    .accordion {
        .card {
            border: none;
            background-color: transparent;
            .card-header {
                background-color: transparent;
                border-bottom: 1px solid $gray;
                margin-bottom: 0;
                position: relative;
                padding: 24px 0 12px 0;
                cursor: pointer;
                @media #{$vpmobile} {
                    padding: 12px 0 6px 0;
                }
                .card--title{
                    position: relative;
                    .para{
                        margin: 0;
                        width: 90%;
                    }
                    &::after {
                        top: 50%;
                        right: 12px;
                        position: absolute;
                        content: "";
                        background-image: url(../img/icon/ic-down.svg);
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: 17px;
                        height: 12px;
                        transition: transform .2s;
                        transform : translate(0, -50%) rotate(180deg);
                        @media #{$vptabletP} {
                            width: 14px;
                            height: 8px;
                        }
                    }
                    p{

                    }
                }
                &.collapsed {
                    .card--title{
                        &::after {
                            transform : translate(0, -50%) rotate(0deg);
                        }
                    }
                }
            }
            .card-body {
                background-color: transparent;
                padding: 0;
                .card-body-wrapper{
                    padding: 20px;
                    ul{
                        li{
                            list-style: disc;
                            margin-left: 30px  
                        }
                    }
                    p{
                        margin-top: 0;
                    }
                }
            }
        }
    }
}