.quote-banner-wrapper{  
    background-color: #95324D;
    .qoute-banner-sec{
        max-width: 1026px;
        width: 100%;
        margin: 0 auto;
        @media #{vptabletP} {
            
        }
    }
    .quote-banner-bg{
        background-image: url(../img/background/bg-quote-message.png);
        background-repeat: no-repeat; 
        background-size: cover;
        padding: 120px 0; 
        @media #{$vpmobile}{
            padding: 60px 0; 
        }  
        h3{
            font-weight: normal;
            text-align: center;
            font-size: 28px;
            
             /*rtl:raw:line-height: 1.5;*/
            // padding: 15px 0;
            color: $white;
            // font-size: 45px;
            @media #{$vptabletP} {
                font-size: 18px;
            }   
            @media #{$vpmobile}{
                // padding: 15px 0;
            }         
        }
        p{
            font-weight: bold;
            color: $beige;
            text-align: center;
            margin-top: 15px;
            @media #{$vpmobile}{
                margin-top: 10px;
            }
        }
    }
}