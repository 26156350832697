.our-partner-wrapper{
    background-color: $lightbeige;
    .our-partner {
        padding: 120px 0;
        background-color: $white;
        @media #{$vpmobile} {
            padding: 60px 0;
        }
    
        .section-wrap {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
    
            @media #{$vpmobile} {
                justify-content: space-between;
                flex-direction: column;
                align-items: flex-start;
            }
    
            .heading2 {
                margin-bottom: 0;
    
                @media #{$lgdesktop} {
                    font-size: 80px;
                }
    
                @media #{$vptabletL} {
                    font-size: 50px;
                }
                @media #{$vpmobile} {
                    font-size: 44px;
                }
            }
    
            .logo-blog {
                padding-bottom: 10px;
                margin-top: 0px;
                @media #{$vpmobile} {
                    width: 100%;
                    overflow: hidden;
                    height: max-content;
                    margin-top: 24px;
                }
                .logo-item {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    max-width: 760px;
                    @media #{$vpmobile} {
                        // flex-direction: column;
                        // align-items: center;
                        width: max-content;
                        height: 100%;
                        display: block;
                        max-width: 100%;
                    }
    
                    li {
                        padding: 0 50px;
                        text-align: right;
    
                        @media #{$lgdesktop} {
                            padding: 0;
                        }
                        @media #{$vpmobile} {
                            padding: 0;
                            width: 90%;
                            margin: 0 auto;
                        }
    
                        img {
                            @media #{$lgdesktop} {
                                max-width: 70%;
                                width: 100%;
                            }
                            @media #{$vptabletL} {
                                max-width: 70%;
                                width: 100%;
                            }
                            @media #{$vpmobile} {
                                width: 177px;
                                margin: 0 auto;
                                display: table;
                                /* height: 121px; */
                                object-fit: contain;
                            }
                        }
    
                        &:last-child {
                            padding-right: 0;
                            // @media #{$vpmobile} {
                            //     padding-bottom: 0px;
                            // }
                        }
    
                        &:first-child {
                            padding-left: 0;
                            // @media #{$vpmobile} {
                            //     padding-top: 40px;
                            // }
                        }
    
                    }
                    .slick-track {
                        display: flex;
                        align-items: center; 
                    }
                }
            }
        }
    }
}