.sec-slider {
    background-color: $white;
    // padding: 0px 40px 91px;
    // padding: 120px 0;
    @media #{$vpmobile} {
        //padding-bottom: 135px;
    }
    .sec-slider-style{
        @media #{$vptabletP} {
            padding-bottom: 75px;
        }
    }
    .slider-box-wrap {
        background-color: $white;
        // padding: 75px 85px;
        display: flex;
        align-items: center;
        position: relative;
        @media #{$vptabletP} {
            flex-direction: column-reverse;
            // padding: 35px 45px;

        }

        @media #{$vpmobile} {
            // padding: 48px 10px 137px;
        }

        .sec-slider-img {
            max-width: 55%;
            flex: 0 0 55%;
            border-radius: 8px;
            overflow: hidden;

            @media #{$vpdesktop} {
                max-width: 50%;
                flex: 0 0 50%;
            }

            @media #{$vptabletP} {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }

        .sec-slider-content {
            max-width: 45%;
            flex: 0 0 45%;
            padding: 0 0 0 80px;

            @media #{$vpdesktop} {
                max-width: 50%;
                flex: 0 0 50%;
            }

            @media #{$vptabletL} {
                padding: 0 30px;
            }

            @media #{$vptabletP} {
                max-width: 100%;
                flex: 0 0 100%;
                padding: 0 0 20px;
            }

            .heading2 {
                max-width: 580px;

                @media #{$vptabletL} {
                    max-width: 320px;
                }
                @media #{$vpmobile} {
                   max-width: 193px;
                }
            }

            .para {

                padding: 30px 0 0 0;

                @media #{$vptabletL} {
                    padding: 10px 0 0 0;
                }

                @media #{$vpmobile} {
                    padding-left: 20px 0;
                }
            }
        }
    }

    .slick-arrow {
        position: absolute;
        bottom: 0px;
        // width: 55px;
        // height: 55px;
        background-repeat: no-repeat;
        text-indent: 0;
        background-color: transparent;
        border: none;
        outline: none;
        z-index: 1;
        // left: calc(55% + 130px);
        left: calc(55% + 80px);
        right: auto;
        @media #{$vpmobile} {
            padding: 0;
        }
        img,svg{
            height: 30px;
            width: auto;
            @media #{$vpmobile} {
                height: 30px;
                width: 30px;
            }
        }

        @media #{$vpdesktop} {
            // left: calc(50% + 130px);
            // left: calc(55% + 80px);
            left: calc(55% + 30px);
        }

        @media #{$vptabletL} {
            // left: calc(50% + 71px);
            left: calc(50% + 30px);
        }

        // @media #{$vptabletP} {
        //     width: 44px;
        // height: 44px;
        // left: calc(50% - 47px);
        // bottom: -85px;

        // }
        @media #{$vptabletP} {
            width: 45px;
            height: 45px;
            left: calc(50% - 60px);
            bottom: 0px;
        }
    }

    .slick-next {
        // left: calc(55% + 203px);
        left: calc(55% + 133px);

        @media #{$vpdesktop} {
            // left: calc(55% + 153px);
            left: calc(55% + 86px);
        }

        @media #{$vptabletL} {
            // left: calc(50% + 141px);
            left: calc(50% + 81px);
        }
        @media #{$vptabletP} {
            left: calc(50% + 5px);
        }
        @media #{$vpmobile} {
            padding: 0;
        }
        // @media #{$vpmobile} {
        //     left: calc(50% - -17px);
            
        // }

        &:hover,
        &:focus {
           .toSvg {
            path{
                fill: rgba(56,56,56,1);
            }
           }
        }
    }

    .slick-prev {
       

        &:hover,
        &:focus {
            .toSvg {
                path{
                    fill: rgba(56,56,56,1);
                }
               } 
        }
    }
}