.basic-detail-form-en-ar{
    font-family: 'Effra';
    /*rtl:raw:direction: ltr;*/
    /*rtl:raw:text-align: left;*/
    .craft-heading{
        .sub-para,.form-main-heading{
            font-family: 'Effra';
            span{
                font-family: 'Harir';
                display: block;
                margin-bottom: 0;
                padding-top: 0px;
                top: 10px;
                position: relative;
            }
        }
    }
    .form-btn-wrapper{
        .form-btn-warp{
            .btn-magenta-out-line{
                /*rtl:begin:ignore*/
                padding: 11px 23px;
                text-align: left;
                /*rtl:end:ignore*/
                .ic-arrow{
                    /*rtl:begin:ignore*/
                    left: auto;
                    right: 20px;
                    transform: scale(1);
                    /*rtl:end:ignore*/
                }
            }
        }
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow{
        /*rtl:begin:ignore*/
        right: 1px;
        left: auto;
        /*rtl:end:ignore*/
        
    }
    .select2-container--default .select2-search--dropdown .select2-search__field{
        /*rtl:begin:ignore*/
        text-align: left;
        /*rtl:end:ignore*/
    }
    .select2-results__options li.select2-results__option--highlighted[aria-selected] {
        /*rtl:begin:ignore*/
        text-align: left;
        /*rtl:end:ignore*/
    }
    .select2-container--default .select2-selection--single .select2-selection__rendered{
        /*rtl:begin:ignore*/
        text-align: left;
        padding: 9px 20px;
        /*rtl:end:ignore*/
        /*rtl:raw:padding: 9px 20px;*/
    }
    .select2-box .input-text-label{
        /*rtl:begin:ignore*/
        top: 38px;
        /*rtl:end:ignore*/
        /*rtl:raw:top: 38px;*/
    }
    .select2-container--default .scrollbar-inner > .scroll-element.scroll-y {
        
        /*rtl:begin:ignore*/
        right: 2px;
        left: auto;
        /*rtl:end:ignore*/
        /*rtl:raw:top: 38px;*/ 
        /*rtl:raw:left: auto;*/ 
    }
    .select2-container--open + .input-text-label{
        /*rtl:begin:ignore*/
        transform: translateY(-40px) translateX(-20px);
        /*rtl:end:ignore*/
        /*rtl:raw:transform: translateY(-40px) translateX(-20px);*/
    }
    .select-box.is--selcted .input-text-label{
        /*rtl:begin:ignore*/
        transform: translateY(-40px) translateX(-20px);
        /*rtl:end:ignore*/
        /*rtl:raw:transform: translateY(-40px) translateX(-20px);*/
    }
    .select2-results__option {
        /*rtl:begin:ignore*/
        padding: 6px;
        /*rtl:end:ignore*/
        /*rtl:raw:padding: 6px;*/
    }
}
input::placeholder{
    color: #A09E9E;
}
/// input Text Style
.form-group{
    position: relative;
    margin-bottom: 0;
    width: 100%;
    &.form-group-padding{
        padding: 15px 0;
        margin-top: 10px;
     }
     &.is--used .input-text-label{
         /*rtl:begin:ignore*/
        transform: translateY(-40px) translateX(-20px);
        /*rtl:end:ignore*/
        color: $magenta;
        font-weight: bold;
        font-size: 16px;
        &--ar{
            /*rtl:begin:ignore*/
            transform: translateY(-40px) translateX(20px);
            font-family: 'Harir';
            /*rtl:end:ignore*/
        }
    }
    &.invalid-field{
        position: relative;
        padding-bottom: 21px;
        &.is--used{
            .input-text-label{
                color: $errrorClr;
            }
            .form-control:focus ~ .input-text-label{
                color: $errrorClr;
            }
            .form-control:focus{
                border-color: $errrorClr;
            }
        }
        .form-control{
            border-color: $errrorClr;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        }
        .error-txt{
            color: $errrorClr;
            display: block;
            font-size: 14px;
            margin: 2px 0 0;
            padding: 0 0 0 7px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;

        }
        .input-text-label{
              
        }
        .form-control:focus ~ .input-text-label{
            color: $errrorClr;
        }
        .form-control:focus{
            border-color: $errrorClr;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        }
    }
    .form-control {
        background-color: transparent;
        border-radius: 8px;
        border: 1px solid #DEC7A6;  
        font-size: 17px;                
        width: 100%; 
        outline: 0;
        position: relative;
        z-index: 1;
        box-shadow: none; 
        padding: 21.5px;
        color: $black;
        /*rtl:begin:ignore*/
        text-align: left;
        /*rtl:end:ignore*/
        &--ar{
            /*rtl:begin:ignore*/
            padding: 24.5px 21.5px 18.5px 21.5px;
            direction: rtl;
            font-family: 'Harir';
            text-align: right;
            /*rtl:end:ignore*/
            
        }
        &#phone-number,&#date-of-birth,&#email,&#po-box,&#emirates-id-number,&#passport-num,&#passport-issue-date,&#passport-expiry-date,#emirates-id-expiry-date{
            font-family: 'Effra';
            /*rtl:raw:padding: 21.5px;*/
        }
    }
    .input-text-label {
        color: $placehoderClr;
        /*rtl:begin:ignore*/
        padding-left: 20px;
        left: 0;
        /*rtl:end:ignore*/
        line-height: 0;
        pointer-events: none;
        position: absolute;
        z-index: 1;
        transform-origin: 0 50%;
        transition: transform 200ms, color 200ms;
        top: 38px; 
        span{
            font-family: 'Harir';
        }
            &--ar{
                /*rtl:begin:ignore*/
                left: auto;
                right: 0;
                padding-left: 0px;
                padding-right: 20px;
                font-family: 'Harir';
                top: 42px;
                /*rtl:end:ignore*/
                    
            }            
    }
    .form-control:focus{
        border-color: $magenta;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        border-width: 1px;
    }
    .form-control:focus ~ .input-text-label {
        border-width: 1px;
        /*rtl:begin:ignore*/
        transform: translateY(-40px) translateX(-20px);
        /*rtl:end:ignore*/
        color: $magenta;
        font-weight: bold;
        font-size: 16px; 
        &--ar{
            /*rtl:begin:ignore*/
            font-family: 'Harir';
            transform: translateY(-40px) translateX(20px);
            /*rtl:end:ignore*/
            
        }

    }               
}
// Select2 Styling
.select2-container{
    width: 100% !important;

}
.select2-container--default .select2-selection--single{
    background-color: $white;
    border-radius: 8px;
    border-bottom-left-radius:8px !important;
    border-bottom-right-radius:8px !important;
    border: 1px solid #DEC7A6;         
    width: 100%; 
    outline: 0;
    box-shadow: none;       
    height: auto;
}
.invalid-field{
    .select2-container--default .select2-selection--single{
        border-color: $errrorClr;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow{
        background-image: url(../img/icon/chevron-down-error.svg);
    }
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    /*rtl:begin:ignore*/
    padding: 9px 20px;
    /*rtl:end:ignore*/
    /*rtl:raw:padding: 13px 20px 5px 20px;*/
    font-size: 17px;
    line-height: 28px;
    color: $black;
    text-transform: capitalize;
    min-height: 45px;
    text-align: left;
}
.is--selcted{
    .input-text-label {
        border-width: 1px;
        transform: translateY(-40px) translateX(-20px);
        color: $magenta;
        font-weight: bold;
        font-size: 16px;
            &--ar{
                /*rtl:begin:ignore*/
                transform: translateY(-40px) translateX(20px);
                font-family: 'Harir';
                /*rtl:end:ignore*/
            }
        }       
} 
.select2-box{
    .input-text-label{
        left: 0;
        /*rtl:raw:padding-right: 20px;*/
        /*rtl:raw:top: 43px;*/
        &.is--selcted {
            transform: translateY(-40px) translateX(-20px);
        }
    }
}
.invalid-field{
    .input-text-label {
        color: $errrorClr;
    }
    .select2-container--open + .input-text-label{
        color: $errrorClr;
    }
}
.select2-container--open + .input-text-label {
    border-width: 1px;
    transform: translateY(-40px) translateX(-20px);
    color: $magenta;
    font-weight: bold;
    font-size: 16px;
    &--ar{
        transform: translateY(-40px) translateX(20px);
        
    }
} 
.select2-container--open .select2-selection--single{
    border-color: $magenta;
    border-width: 1px;
    border-radius: 8px;
    z-index: 3;
    position: relative;
    box-shadow: -1px -1px  6px rgba(0, 0, 0, 0.16);
}
.select2-container--open .select2-dropdown--below{
    border-color: $magenta;
    border-width: 1px;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    top: 0px;
    margin-top: -3px;
    z-index: 2;
    padding-top: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.invalid-field{
    .select2-container--open .select2-dropdown--below{
        border-color: $errrorClr;
    }
}
.select2-container--default .select2-selection--single .select2-selection__arrow{
    background-image: url(../img/icon/chevron-down.svg);
    background-repeat: no-repeat;
    width: 45px;
    height: 45px;
    font-size: 32px;
    background-position: center;
    transition: transform 200ms;
    /*rtl:raw:left: 1px;*/
    /*rtl:raw:right: auto;*/
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow {
    transform: rotate(180deg);
}
.select2-container--default .select2-selection--single .select2-selection__arrow b{
    display: none;
}
.select2-results__options{
    padding: 0 20px;
    
    li{ 
        border-bottom: 1px solid #000;
        border-left: 0;
        border-radius: 0;
        width: 90%;
        color: $magenta;
        margin: 0 auto;
        // &.select2-results__option--highlighted[aria-selected="true"]{
        //     border-width: 0;
        //     padding: 0;
        // }
        &.select2-results__option.select2-results__option--highlighted{
            background-color: transparent;
        }
        &.select2-results__option--highlighted[aria-selected]{
            background-color: transparent;
            color: $magenta;
            
            }

    }
}
.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: transparent;
}
.scrollbar-inner>.scroll-element .scroll-element_track {
    background-color: transparent;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder{
    color: $magenta;
}
.select2-container--default.select2-container--disabled .select2-selection--single{
    background-color: transparent;
}
.select2-container--default.select2-container--disabled + .input-text-label{
    color: $black; 
}
.select2-container--default .scrollbar-inner>.scroll-element.scroll-y {
    height: 100%;
    right: 2px;
    top: 0;
    width: 8px;
}
span#select2-select-emirate-container + span.select2-selection__arrow {
    display: none;
}
// Radio-Btn1 Styling
.form-check{
    /*rtl:begin:ignore*/
    margin-right: 20px;
    margin-left: 0;
    /*rtl:end:ignore*/
    @media #{$vpmobile} {
        margin-right: 0px;
    }
    input[type="radio"] {
        visibility: hidden;
        position: absolute;
         &:checked {
           + label {
             background-color: $magenta; 
             color: $white;  
             border: 1px solid $magenta;     
           }
         }
       } 
    label{               
        background-color: rgba(92, 92, 92, 0.13);
        border-radius: 8px;
        padding: 9px 35px;
        color: #A09E9E;
        border: 1px solid $beige;
        cursor: pointer;
        transition: border-color 0.35s ease 0s;
        
        &:hover{
            border-color: $magenta;
            color: $magenta;
        }
        @media #{$vpxsmobileSmall} {
            padding: 8px 23px;
        }
    }
}

// Phone Masking Input
.masking-form-group{
    .form-control{
        &::placeholder{
            opacity: 0;
        }
        &:focus{
            &::placeholder{
                
                opacity: 1;
            }
        }
    }
}
// Date Picker Masking Input
.datepickes-form-group{
        position: relative;
        img,svg{
            position: absolute;
            top: 26px;
            /*rtl:begin:ignore*/
                left: 17px;
            /*rtl:end:ignore*/
            width: 20px;
            height: 20px;
            opacity: 1;
            z-index: 0;
        }
    .input-text-label{
        /*rtl:begin:ignore*/
        padding-left: 48px;
        /*rtl:end:ignore*/
    }
}
.form-control:focus + svg{
    opacity: 0;
}
.form-control:focus ~ svg{
    opacity: 0;
}
.invalid-field{
    img,svg{
        fill: $errrorClr;
        path{
        }
    }
    .form-control:focus + svg{
        opacity: 0;
    }
    .form-control:focus ~ svg{
        opacity: 0;
    }   
}
.form-control:focus ~ .input-text-label{
    /*rtl:begin:ignore*/
    padding-left: 20px;
    /*rtl:end:ignore*/
}
.is--used{
    img,svg{
        opacity: 0;
    }
    .input-text-label{
        /*rtl:begin:ignore*/
        padding-left: 20px;

        /*rtl:end:ignore*/
    }
}
// ATTACHED file Input
.input-container-attached{
    // margin-top: 10px;
    margin-top: 0px;
    .form-group{
        padding-bottom: 18px;
        &.invalid-field{
            p{
                color: $errrorClr;
            }
            .attached-file-input{
                border-color: $errrorClr;
            }
        }
    }
    p{
        font-size: 17px;
        color: $magenta;
        font-weight: 600;
        margin-bottom: 3px;
        margin-top: 0;
        span{
            font-family: 'Harir';
            display: block;
            margin-top: 8px;
        }
    }
    .attached-file-input{
        background-color: $white;
        border-radius: 8px;
        border: 1px solid #DEC7A6;  
        // font-size: 17px;                
        font-size: 15px;                
        width: 100%;  
        // padding: 10px 7px;
        /*rtl:begin:ignore*/
        padding: 8px 15px 8px 7px;
        /*rtl:end:ignore*/
        label{
            
        }
        .descp-size{
            color: #65657b;
            font-size: 17px;
        }
    }

}
.input-file {
    display: flex;
    align-items: center;
    @media #{$lgdesktopSmall2} {
        display: block;
     }
    input[type="file"] {
        visibility: hidden;
        width: 1px;
        height: 1px;
        position: absolute;
    }

    .btn-12 {
            text-align: center;
            background-color: $magenta;
            color: $white;
            // min-width: 150px;
            // padding: 9px 0;
            // border-radius: 8px;
            // margin-bottom: 0;
            min-width: 110px;
            padding: 6px 0;
            border-radius: 5px;
            margin-bottom: 0;
            /*rtl:begin:ignore*/
            margin-right: 13px;
            /*rtl:end:ignore*/
            display: inline-block;
            max-height: 34px;
            @media #{$lgdesktopSmall2} {
                display: table;
                min-width: 100%;
                margin: 0 auto;
                padding: 10px 0
            }
    }
    
    .file-selected {
        color: $placehoderClr;
            // font-size: 17px;
            font-size: 14px;
            display: block;
            line-height: 1.2;
            margin-top: 0px;
            line-break: anywhere;
            span{
                display: block;
                font-family: 'Harir';
                margin-top: 3px;
                @media #{$lgdesktopSmall2} {
                    display: inline-block;
                 }
            }
            @media #{$lgdesktopSmall2} {
                margin-top: 4px;
               text-align: center;
               display: block;
            }
    }
}
// Choose Type Radio Btn
.custom-card-radio {
    margin: 0;
    padding: 0 17px;
    position: relative;
    @media #{$vpmobile}{
        padding: 0 0px;
    }
    .invalid-field {
        .error-txt{
            /*rtl:raw:text-align: right;*/
            bottom: -28px;
        }
    }
    .form-group{
        margin: 0;
        height: 100%;
    }
    label {
        max-width: 100%;
        background-color: $white;
        border-radius: 8px;
        padding-bottom: 29px;
        padding-top: 62px;
        padding-left: 10px;
        padding-right: 10px;
        width: 218px;
        color: $darkgray;
        cursor: pointer;
        font-weight: bold;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
        position: relative;
        text-align: center;
        display: block;
        transition: background-color 0.35s ease 0s, border-color 0.35s ease 0s, color 0.35s ease 0s;
        border: 1px solid #fff;
        margin: 12.5px auto;
        height: 100%;
        &:hover{
            border-color: $magenta;
            color: $magenta;
        }
        @media #{$vp-min-mobile} {
            @media #{$height700} {
                padding-top: calc(100vh - 648px);
                padding-bottom: calc(100vh - 648px);
                min-height: 156px;
                display: flex;
                flex-direction: column;
                justify-content: center
            }
        }
        @media #{$vptabletP} {
            width: 185px;
            padding-bottom: 45px;
            padding-top: 70px
        }
        @media #{$vpmobile} {
            width: 100%;
            max-width: 328px;
            min-width: 328px;
            padding: 26px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        @media #{$vpxsmobileSmall} {
            max-width: 300px;
            min-width: 300px;
        
        }
        .icon-box {
            display: block;
            margin: 0 auto  30px auto;
            // height: 78px;
            // width: 78px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media #{$vp-min-mobile} {
                @media #{$height700} {
                    margin-bottom: 6px;
                }
            }
            @media #{$vpmobile}{
                margin-bottom: 0;
                margin-right: 17px;
                margin-left: 0;
            }
            svg, img {
                max-height: 100%;
                max-width: 100%;
                width: 68px;
                height: 68px;
                @media #{$vp-min-mobile} {
                    @media #{$height700} {
                        width: 50px;
                        height: 50px;
                    }
                }
                @media #{$vpmobile}{
                    width: 36px;
                    height: 36px
                }
                path,
                polygon,
                rect {
                    fill: $magenta;
                    transition: all 0.35s ease 0s;
                }
            }
        }
        .sub-para{
            line-height: 1.1;
        }
    }

    input[type="radio"] {
        visibility: hidden;
        position: absolute;
        width: 0;
        height: 0;

        &:checked {
            ~ label {
                background-color: $magenta;
                color: $white;
                border-color: $magenta;

                img,
                svg {

                    path,
                    polygon,
                    rect {
                        fill: $white !important;
                    }
                }
            }
        }
    }

    &.style--small {
        margin: 12.5px 0;
        position: unset;
        .form-group{
            height: 100%;
            position: unset;
            padding-bottom: 0;
        }
        @media #{$lgdesktopSmall2}{
            padding: 0 12px;
        }
        .error-txt{
            padding: 0 0 0 23px;
        }
        label {
            width: 245px;
            // min-height: 130px;
            // padding: calc(100% - 222px) 10px;
            padding: 22px 10px 22px 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // height: 100%;
            margin: 0;
            @media #{$vp-min-mobile} {
                @media #{$height700} {
                    // padding-bottom: 15px;
                    // padding-top: 19px;
                    // min-height: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: center
                }
            }
            // 1200
            @media #{$lgdesktop} {
                width: 192px;
            }
            @media #{$lgdesktopSmall2}{
                width: 168px;
                // padding-bottom: 20px;
                // padding-top: 20px;
            }
            @media #{$vptabletP} {
                width: 200px;
            }
            .sub-para {
                font-size: 17px;
                line-height: 1.2;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                margin-top: 10px;
            }
            img,img{
                @media #{$vp-min-mobile} {
                    @media #{$height700} {
                        width: 30px !important;
                        height: 30px !important;
                    }
                }
            }
        }
        span {
            display: block;
            &.icon-box {
                height: 36px;
                width: 36px;
                margin-bottom: 0px;
            }
        }
    }
}
.craft-button-select2{
    position: relative;
    max-width: 1408px;
}
.craft-button {
    &.craft-button-first-step{
        position: relative;
        padding-bottom: 21px;
        .custom-card-radio{
          position: unset;  
        }
        .form-group{
            position: unset;
            padding-bottom: 0;
        }
        .error-txt{
            text-align: center;
        }
    }
}
.craft-sec,.craft-sec-2{
    .craft-button {
        &.craft-button-first-step{
            position: relative;
            bottom: 0;
            left: 0;
            .custom-card-radio{
              position: unset;  
            }
            .form-group{
                position: unset;
            }
        }
        &.craft-button-select2{
            display: flex;
            
            @media #{$vpmobile}{
                
                display: none;
            }
        
        }
    }
}
.choose-type-select2{
    display: none;
    @media #{$vpmobile}{
        display: block;
        max-width: 390px;
        margin: 0 auto;
        width:100%
    }
}
.server-error-style{
    color:$errrorClr;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Effra' !important;
}

.more-categories{
    display: none;
    label{
        display: flex;
        flex-direction: row;
        align-items: center;
        border-color: $magenta;
        background-color: $magenta;
        color:$white;
        // min-height: 113px !important;
        @media #{$lgdesktop} {
            flex-direction: column;
            padding: 0;
            justify-content: center;
            min-height: auto;
            display: flex;
            // min-height: 113px !important;
        }
        @media #{$lgdesktopSmall2}{
            // min-height: 113px !important;
        }
        @media #{$vptabletP} {
            // min-height: 113px !important;
        }
        @media #{$vp-min-mobile} {
            @media #{$height700} {
                display: flex !important;
                // min-height: 102px !important;
            }
        }
        .icon-box{
            background-color: $white;
            display: flex;
            justify-content: center !important;
            align-items: center !important;
            border-radius: 50%;
            color:$magenta;
            font-size: 25px;
            font-family: 'Effra';
            @media #{$lgdesktop} {
                margin: 0 0px 12px 0 !important;
            }
        }
        .custom-card-titile{
            color: $white;
            font-weight: normal;
            /*rtl:raw:padding-top: 2px;*/
        }
    }
}
span#select2-nationality-container + span.select2-selection__arrow {
    display: none;
}
span.flatpickr-day.disabled {
    pointer-events: none;
    opacity: 0.3;
}
.flatpickr-day.today {
    border-color: $magenta;
}
.flatpickr-day.prevMonthDay,.flatpickr-day.nextMonthDay{
    color: inherit;
}
.flatpickr-day.today:hover, .flatpickr-day.today:focus {
    border-color: $magenta;
    background: $magenta;
    color: #fff;
}
.flatpickr-innerContainer{
    font-family: 'Effra';
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid $magenta;
    border-radius: 5px;
    margin-top: 5px;
    width: 98%;
    margin: 5px auto 0 auto;
    display: table;
    background-color: transparent;
    text-align: left;
}
.select2-results__options li.select2-results__option--highlighted[aria-selected] {
    text-align: left;
}
.select2-results__options li{
    &:last-child{
        border-width: 0;
        // padding-top: 6px;
    }
}
.select2-results__option {
    /*rtl:begin:ignore*/
    padding: 6px;
    /*rtl:end:ignore*/
    /*rtl:raw:padding: 13px 6px 5px 6px;;*/
    padding: 13px 6px 5px 6px;
}
.select2-dropdown{
    border-color: $magenta;
    border-radius: 8px;
}
//Disclaimer
.disclaimer-sec {
    max-width: 100%;
    margin: 0;
    padding: 0 0 0 0;
    width: 90%;

    .form-group.invalid-field .error-txt{
        margin-bottom: -22px;
        /*rtl:begin:ignore*/
        left: 19px
        /*rtl:end:ignore*/
        /*rtl:raw:text-align: left;*/
         /*rtl:raw:direction: ltr;*/
    }
    .custom-control-label:before{
        background-color:$white;
    }
    .custom-checkbox .custom-control-input:checked~.custom-control-label::before{
        background-color:$magenta;
    }
    .form-control{
          position: absolute;
          height: 100%;
        cursor: pointer;
    }
    .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: none;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff;
        border-color: transparent;
        background-color: transparent;
    }
    .custom-control-label::before {
        position: absolute;
        /*rtl:begin:ignore*/
        left: -1.5rem;
        /*rtl:end:ignore*/
    }
    .custom-control-label::after{
        /*rtl:begin:ignore*/
        left: -1.5rem;
        /*rtl:end:ignore*/
    }
    .custom-control {
        position: relative;
        display: block;
        min-height: 100%;
        /*rtl:begin:ignore*/
        padding: 0 0 0 1.5rem;
        /*rtl:end:ignore*/
        height: 100%;
    }
    .form-group{
        padding-bottom: 0;
    }
    label{
        background-color: transparent;
        padding: 0;
        border-width: 0;
        color: $disclaimer;
        span{
            font-family: 'Harir';
            display: block;
            margin-top: 10px;
            text-align: right;
            /*rtl:raw:text-align: right;*/
            /*rtl:raw:direction: rtl;*/
        }
        &:hover{
            color: $disclaimer;
        }
        a{
            font-size: inherit;
            line-height: inherit;
            text-decoration: underline;
            color: $magenta;
            transition: color 0.25s ease-in-out;
            position: relative;
            z-index: 2;
            &:hover{
                color: $lightmagenta;
            }
        }
    }
}
.phone-number-container{
    .iti {
        position: relative;
        display: block;
    }
    .iti--separate-dial-code .iti__selected-flag {
        background-color: transparent;
        opacity: 0;
        visibility: hidden;
    }
    .is--used{
        .iti--separate-dial-code .iti__selected-flag {
            opacity: 1;
            visibility: visible;
        }
    }
    .error-txt{
        bottom: -21px !important;
    }
}
.iti__country-list{
    /*rtl:raw:direction: ltr*/
    /*rtl:raw:text-align: left;*/
    font-family: 'Effra' !important;
}
.iti--container {
    
    /*rtl:raw:direction: ltr;*/
}