.discover-steps-wrapper{  
    background-color: $purple;
    @media #{$vptabletP}{
        
    }  
    .headin2-variant-2{            
        margin-bottom: 20px;
        color: $white;
        max-width: 50%;
        @media #{$lgdesktop}{
            max-width: 65%;
        }
        @media #{$vpmobile}{
            margin-bottom: 20px;
            max-width: 100%;
        }                       
        span{
        position: relative;   
        white-space: nowrap;         
            &::after{                
            position: absolute;
            left: 0;
            bottom: -4px;
            display: inline-block;
            content: "";
            border-bottom: 5px solid $magenta;
            width: 100%;                
            }
        }
        @media #{$vpmobile}{
            margin-left: 0;
        }
    }
    .sec-aims{
        margin: 40px 0 0;
        padding-right: 83px;
        @media #{$lgdesktop} {
            padding-right: 40px;
        }
        @media #{$lgdesktopSmall2} {
            padding-right: 30px;
        }
        @media #{$vptabletL}{
            // padding-right: 60px;
        }
        @media #{$vptabletP}{
            margin: 15px 0;
        }
        @media #{$vpmobile}{
            padding: 0 5px;
        }
        .para{
            font-weight: bold;
            margin: 40px 0 40px;
            position: relative;
            color: $white;
            @media #{$vptabletP}{
                margin: 25px 0 25px;
            }
            &::after{                
                content: "";
                width: 90%;
                bottom: -20px;
                height: 2px;
                background: #ffffff;
                position: absolute;
                left: 0;   
                @media #{$vpmobile}{
                    bottom: -16px;
                }             
                }
        }
        .sub-para{
            color: $white;
            }
        .sec-aim-icon{
            img, svg {
                height: 55px;
                width: 65px;
                @media #{$vptabletL}{
                    height: 65px;
                    width: 50px;                    
                }
            }
        }
    }
    
}
