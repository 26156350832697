.craftPerformance-about-sec{
    background-color: $lightMidGold;
    // margin: 60px 0;
    margin: 24px 0;
    
    .cpa-wrapper{
        position: relative;
    }
    .cpa-title{
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-11%, -50%);
        @media (max-width: 991px) {
            transform: translate(-50%, -50%);
        }
        @media (max-width: 495px) {
            transform: translate(-50%, -50%);
        }
        .headingVertical{
            writing-mode: vertical-rl;
            margin: 0;
            transform: rotate(180deg);
            opacity: 0.5;
            line-height: 1;
        } 
    }
    .cpa-content{
        padding-left: 292px;
        padding-top: 20px;
        padding-bottom: 20px;
        min-height: 358px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width: 991px) {
            padding-left: 125px;
        }
        h3{
            color: $goldVariant;
            margin: 0;
            font-weight: 700;
        }
    }
}
.craftPerformance-discover-sec{
    .cpd-wrapper{
        display: flex;
        position: relative;
        @media (max-width: 991px) {
            flex-direction: column;
        }
        .js-discovery-slider-nav{
            position: unset;
        }
        .slick-arrow{
            outline: none;
            border: 0;
            @media (max-width: 1600px) {
                width: 70px;
                height:auto;
            }
            @media (max-width: 991px) {
                width: 56px;
                height:auto;
            }
            img,svg{
                width: auto;
            }
            &.slick-next{
                margin-left: 80px;
                @media (max-width: 991px) {
                    margin-left: 80px;
                }
            }
            &.slick-disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }
        .cpd-content{
            flex: 0 0 33%;
            max-width: 33%;
            padding-bottom: 0px;
            margin-bottom: 0px;
            padding-right: 25px;
            position: relative;
            @media (max-width: 991px) {
                flex: 0 0 100%;
                max-width: 100%;
                padding-bottom: 36px;
                margin-bottom: 36px;
            }
            h2{
                margin-bottom: 24px;
            }
            p{
                max-width: 424px;
                @media (max-width: 991px){
                    max-width: 100%;
                }
            }
        }
        .cpd-slider{
            flex-grow: 1;
            max-width: 100%;
            overflow: hidden;
            z-index: 1;
            @media (max-width: 991px) {
                // flex: 0 0 100%;
                // max-width: 100%;
            }
            .cpd-slider-item-wrap{
                .cpd-slider-item{
                    transform: scale(1);
                    transition: all 0.3s ease-out;
                    @media (max-width: 991px) {
                        transform: scale(0.8);
                    }
                }
                &.slick-current{
                    .cpd-slider-item{
                        transform: scale(1);
                        @media (max-width: 991px) {
                            transform: scale(1);
                        }
                    }
                }

            }
            .slick-list{
                overflow: visible !important;
                width: 100%;
                max-width: 90%;
                @media (max-width: 767px) {
                    max-width: 70%;
                }
            }
            .slick-slider {
                margin:0 -8px;
                @media (max-width: 991px) {
                    margin:0 0px;
                }
            }
            .slick-slide {
                margin-right:8px;
                margin-left:8px;
                @media (max-width: 991px) {
                    margin-right:0px;
                    margin-left:0px;
                }
            }
            .cpd-slider-item{
                // max-width: 96%;
                .cpdsi-img{
                    position: relative;
                    .cpdsi-img-overlay{
                        background-color:rgba(41, 40, 38, 0.30);
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        top: 0;
                        left: 0;
                        img,svg{
                            width: auto;
                            @media (max-width: 1400px) {
                                width: 48px;
                            }
                            @media (max-width: 495px) {
                                width: 42px;
                            }
                        }
                    }
                    .cpdsi-img-warp{
                        padding-top: 62%;
                        position: relative;
                        img{
                            height: 100%;
                            object-fit: cover;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }

                }
                .cpdsi-content{
                    display: block;
                    // @media (max-width: 991px) {
                    //     display: none;
                    // }
                }
            }
        }
    }
}
.gallery-sec{
    position: relative;
    // transform: translateY(-50%);
    margin-top: -128px;
    z-index: 1;
    @media (max-width: 991px) {
        margin-top: -176px;
    }
    @media (max-width: 495px) {
        margin-top: -176px;
    }
    &.sty1 {
        margin-top: 20px;
        padding: 0 18px;
        @media (max-width: 991px) {
            margin-top: 20px;
        }
        @media (max-width: 495px) {
            margin-top: 20px;
        }
    }
    .gs-wrapper{
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        @media (max-width: 991px) {
            flex-direction: column;
        }
        .gsw-title{
            flex: 0 0 292px;
            max-width: 292px;
            flex-direction: column;
            margin-bottom: 54px;
            @media (max-width: 991px) {
                display: flex;
                justify-content: space-between;
                margin-bottom: 35px;
                flex-direction: row;
                width: 100%;
                max-width: 100%;
                padding-right: 15px;
                flex: 1;
            }
            .heading2{
                margin-bottom: 60px;
                @media (max-width: 991px) {
                    margin-bottom: 0px;
                }
            }
            .slider-navigation{
                .slidenumbers {
                    small{
                        font-size: 50%;
                        font-weight: 700;
                    }
                }
            }
        }
        .gsw-slider{
            flex-grow: 1;
            max-width: 100%;
            overflow: hidden;
            height: 100%;
            padding-bottom: 18px;
            .slick-list{
                overflow: visible;
                width: 100%;
                max-width: 90%;
            } 
            .slick-slider {
                margin:0 -7.5px;
            }
            .slick-slide {
                margin-right:7.5px;
                margin-left:7.5px;
            }
            .gsws-img{
                // max-width: 98%;
                position: relative;
                .gsws-img-overlay{
                    background-color:rgba(41, 40, 38, 0.60);
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    img,svg{
                        width: auto;
                        @media (max-width: 1400px) {
                            width: 58px;
                        }
                        @media (max-width: 495px) {
                            width: 42px;
                        }
                    }
                }
            }
            .slide-box-wrapper {
                height: 100%;
                .box-content {
                    border: 1px solid #BFC6DA;
                    padding: 30px 33px;
                    display: block;
                    height: 100%;
                    .licenses-title {
                        margin-bottom: 25px;
                        span {
                            color: #CFBFA7;
                            font-size: 15px;
                        }
                        h5 {
                            font-size: 18px;
                            font-weight: 700;
                            line-height: 1.4;
                            color: #292826;
                        }
                    }
                    .licenses-date {
                        span {
                            color: #CFBFA7;
                            font-size: 15px;
                            font-weight: 700;
                        }
                        h5 {
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 1.4;
                            color: #292826;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .slick-dots{
            li{
                display: none;
                &.slick-active{
                    display: block;
                }
            }
        }
    }
}

.slickIndicator {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    align-items: center;
    margin-top: 54px;
    width: 90%;
    @media (max-width: 991px) {
        margin-top: 45px;
    }
    // .slick-dots {
    //     flex-shrink: 0;
    //     margin-right: 10px;
    //     margin-bottom: 10px;
    //     li {
    //         display: none;
    //         font-size: 23px;
    //         font-weight: 900;
    //         color: $iGray;
    //         small {
    //             font-weight: 400;
    //             font-size: 15px;
    //         }
    //         &.slick-active {
    //             display: block;
    //         }
    //     }
    // }
    .slick-progress {
        width: 100%;
        display: block;
        position: relative;
        height: 2px;
        background-color: #969285;
        span {
            position: absolute;
            height: 6px;
            // width: 0;
            background-color: #292826;
            transition: all .35s ease 0s;
            top: 0;
            transform: translateY(-50%);
        }
    }
}
.artisan-noResult {
    padding: 100px 0;
    h1 {
        font-size: 45px;
        color: #CFBFA7;
        @media (max-width:767px) {
            font-size: 30px;
        }
    }
    &.find-result--text {
        padding: 100px 0 30px;
    }
}
.js-gallery-slider-wrapper {
    .slickIndicator {
        width: 65%;
        position: absolute;
        @media (max-width: 991px) {
            width: 80%;
        }
    }
}