.map-main-wrapper{
    button{
        &:focus{
            outline: none;

        }
    }
    .msg-btn{
        width: 78px;
        height: 78px;
        border-radius: 50%;
        background-color: $magenta;
        border: 0;
        position: fixed;
        right: 90px;        
        bottom: 90px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        z-index: 7;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.3s ease;
        &:hover{
            background-color: $iPink;
        }
        @media #{$vpmobile} {
            right: 30px;         
            bottom: 70px;
            width: 50px;
            height: 50px;
        }
        img, svg{
            width: 100%;
            height: 100%;
            max-width: 34px;
            max-height: 34px;
            @media #{$vpmobile} {
                max-width: 24px;
                max-height: 24px;
            }
        }
    }
    .wrapper-map{
        background-color: $magenta;
        width: 562px;
        position: fixed;
        top: 0;
        right: 0;
        transform: translateX(100%);
        transition: transform 200ms ease-in-out;
        z-index: 9;
        &.showing{
            right: 0;
            transform: translateX(0);
            z-index: 9;
        }
        
        @media #{$vptabletL} {
            width: 462px;
        }
        @media #{$vptabletP} {
            width: 400px;
        }
        @media #{$vpmobile} {
            width: 100%;
        }
        .close-btn{
            background-color: transparent;
            border: 0;
            position: absolute;
            right: 25px;
            top: 25px;
            z-index: 2;
            img,svg{
                path,
                rect,
                polygon,
                line {
                    transition: all 0.3s ease;
                }
            }
            &:hover{
                img,svg {
                    path,
                    rect,
                    polygon,
                    line {
                        stroke: $beige;
                    }
                }
            }
        }
        .map-bg{
            position: absolute;
            top: 50%;
            transform: translatey(-50%); 
            /*rtl:raw:transform: translatey(-50%) scale(-1);*/          
        }
        .map-content-sec{
            padding: 0 60px;
            position: relative;
            z-index: 1;
            @media #{$vpmobile} {
                padding: 0 20px;
            }
            @media #{$vp-min-mobile} {
                @media #{$height800} {
                    padding: 17px 52px; 
                }
            }
            .map-txt-sec{                            
                margin-top: 55px; 
                @media #{$vpmobile} {
                margin-top: 35px; 
                
                }  
                @media #{$vp-min-mobile} {
                    @media #{$height800} {
                        margin-top: 36px; 
                    }
                }             
                .txt-sec-heading{
                    padding: 24px 0;
                    width: 90%;
                    @media #{$vpmobile} {
                        padding: 20px 0;
                        @media #{$height700} {
                            padding: 20px 0 0px 0;
                        }
                    } 
                    @media #{$vp-min-mobile} {
                        @media #{$height800} {
                            padding: 0;

                        }
                    }
                    h3{
                        color: $white;
                        font-weight: bold;
                    }
                }
                .heading-sec-para{
                    
                    color: $white;
                }
                .txt-sec-para{
                    max-width: 380px;
                    p{
                        color: $white;                        
                    }
                }
                .contact-sec{
                    padding: 0px 0 18px 0;
                    display: flex;
                    flex-direction: column;
                    font-family: 'effra';
                    @media #{$vpmobile} {
                        padding: 0;
                    }
                    @media #{$vp-min-mobile} {
                        @media #{$height800} {
                            padding: 16px 0; 
                        }
                    }
                    p{
                        margin: 0 0 17px 0;
                        color: $white;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        .para-sm{
                            transition: all 0.3s ease;
                            color: inherit;
                            display: inline-block;
                            /*rtl:begin:ignore*/
                            text-align: left;
                            direction: ltr;
                            /*rtl:end:ignore*/

                        }
                        &:hover{
                            color: $beige;
                            img,svg{
                                path,
                                rect,
                                polygon,
                                line{
                                    fill: $beige;
                                } 
                            }
                        }
                        img,svg{  
                            // transition: all 0.3s ease;
                            margin-right: 14px;
                            width: 23px;
                            height: 23px;
                            path,
                            rect,
                            polygon,
                            line {
                                transition: all 0.3s ease;
                            }
                        }
                    }
                }
            }
        }
        .map-sec{
            max-width: 437px;
            height: 400px;  
            padding: 32px 0; 
            display: none;
            @media #{$vpmobile} {
                max-width: 400px;
                height: 330px; 
                @media #{$height700} {
                    height: 240px;
                    padding: 0; 
                }
            } 
            @media #{$vp-min-mobile} {
                @media #{$height800} {
                    height: 344px;
                    padding: 0; 
                }
                @media #{$height700} {
                    height: 260px;
                    padding: 0; 
                }
            }                          
            iframe{
                border-radius: 8px
            }
        }
    }
}

