h1,
.heading1 {
	font-size: 60px;
	line-height: 1.0;
	font-weight: bold;
	@include primaryfontFamily;

	&.black {
		color: $darkgray;
	}

	&.darkgray {
		color: $darkgray;
	}

	&.beige {
		color: #DDC7A6;
	}
	&.gold-variant{
		color: $goldVariant;
	}
	@media #{$vpmobile} {
		font-size: 40px;
	}

}

h2,
.heading2 {
	font-size: 45px;
	/*rtl:begin:ignore*/
	line-height: 0.95;
	/*rtl:end:ignore*/
	/*rtl:raw:line-height: 1.5;*/
	font-weight: bold;
	@include primaryfontFamily;

	&.black {
		color: $darkgray;
	}
	&.darkgray {
		color: $darkgray;
	}
	&.beige {
		color: #DDC7A6;
	}
	&.gold-variant{
		color: $goldVariant;
	}
	&.whiteClr{
		color: $white;
	}
	@media #{$vptabletL} {
		font-size: 40px;
	}

	@media #{$vpmobile} {
		font-size: 32px;
	}
}

.headin2-variant-2 {
	font-size: 45px;
	line-height: 1.2;
	font-weight: bold;
	@include primaryfontFamily;

	&.black {
		color: $darkgray;
	}
	&.darkgray {
		color: $darkgray;
	}

	&.beige {
		color: #DDC7A6;
	}
	@media #{$vptabletL} {
		font-size: 40px;
	}
	@media #{$vpmobile} {
		font-size: 32px;
	}
}

h3,
.sub-title {
	font-size: 28px;
	line-height: 1.2;
	font-weight: normal;
	@include primaryfontFamily;

	&.black {
		color: $darkgray;
	}

	&.darkgray {
		color: $darkgray;
	}

	&.beige {
		color: #DDC7A6;
	}
	&.white {
		color: $white;
	}

	@media #{$vpmobile} {
		font-size: 26px;
	}
} 
.sub-title--modifier {
	font-size: 24px;
	line-height: 1.2;
	font-weight: normal;
	@include primaryfontFamily;

	&.black {
		color: $darkgray;
	}

	&.darkgray {
		color: $darkgray;
	}

	&.beige {
		color: #DDC7A6;
	}
	&.white {
		color: $white;
	}
	@media #{$lgdesktop} {
		font-size: 18px;
	}
	@media #{$vpmobile} {
		font-size: 18px;
	}
}
.para {
	font-size: 18px;
	line-height: 1.5;
	font-weight: normal;

	&.black {
		color: $darkgray;
	}

	&.darkgray {
		color: $darkgray;
	}

	&.beige {
		color: #DDC7A6;
	}
	@media #{$vptabletL} {
		font-size: 16px;       
	}
}
.sub-para {
	font-size: 18px;
	line-height: 1.5;
	&.black {
		color: $darkgray;
	}

	&.darkgray {
		color: $darkgray;
	}

	&.beige {
		color: #DDC7A6;
	}

	@media #{$vptabletL} {
		font-size: 16px;
	}
}

p,
.para-sm {
	// font-size: 18px;
	font-size: 16px;
	line-height: 1.2;
	font-weight: normal;
	&.cop-right-size{
		/*rtl:begin:ignore*/
		font-size: 16px;
		/*rtl:end:ignore*/
		/*rtl:raw:font-size: 16px;*/
		.copy-right-sybl{
			font-size: 31px;
			line-height: 0;
			position: relative;
			top: 2px;
		}
	}
	&.black {
		color: $darkgray;
	}

	&.darkgray {
		color: $darkgray;
	}

	&.beige {
		color: #DDC7A6;
	}
	
	@media #{$vptabletP}{
		font-size: 16px;
	}
	@media #{$vpmobile} {
		font-size: 14px;
	}
}


a,
.link {
	font-size: 18px;
	line-height: 1.2;
	font-weight: normal;

	&.black {
		color: $darkgray;
	}

	&.darkgray {
		color: $darkgray;
	}

	&.beige {
		color: #DDC7A6;
	}
	@media #{$vpdesktop}{
		font-size: 20px;
	}
	@media #{$vpmobile} {
		font-size: 16px;
	}
}

.sub-link {
	font-size: 16px;
	line-height: 1.2;
	font-weight: normal;

	&.black {
		color: $darkgray;
	}

	&.darkgray {
		color: $darkgray;
	}

	&.beige {
		color: #DDC7A6;
	}
	&.disclaimerClr{
		color: $disclaimer;
	}
	@media #{$vpmobile} {
		font-size: 16px;
	}
}


.sub-heading {
	position: relative;
	&.beige-after{
		color: $darkgray;
	}
}
.form-main-heading{
	font-size: 50px;
    line-height: 1.2;
	font-weight: bold;
	@include primaryfontFamily;

	&.black {
		color: $darkgray;
	}
	&.darkgray {
		color: $darkgray;
	}

	&.beige {
		color: #DDC7A6;
	}
	&.gold-variant{
		color: $goldVariant;
	}
	// @media #{$vpdesktop} {
	// 	font-size: 45px;
	// }

	// @media #{$vptabletL} {
	// 	font-size: 40px;
	// }

	@media #{$vptabletP} {
		font-size: 35px;
	}

	@media #{$vpmobile} {
		font-size: 30px;
	}
}

.headingVertical {
	font-size: 126px;
	/*rtl:begin:ignore*/
	line-height: 0.95;
	/*rtl:end:ignore*/
	/*rtl:raw:line-height: 1.5;*/
	font-weight: bold;
	@media (max-width:767px) {
		font-size: 145px;
	}
	@include primaryfontFamily;

	&.black {
		color: $darkgray;
	}
	&.darkgray {
		color: $darkgray;
	}

	&.beige {
		color: #DDC7A6;
	}
	&.gold-variant {
		color: $goldVariant;
	}
	// @media #{$vptabletL} {
	// 	font-size: 48px;
	// }

	// @media #{$vpmobile} {
	// 	font-size: 32px;
	// }
}

.sub-title--carfPerformance {
	line-height: 1.2;
	font-weight: normal;
	@include primaryfontFamily;

	&.black {
		color: $darkgray;
	}

	&.darkgray {
		color: $darkgray;
	}

	&.beige {
		color: #DDC7A6;
	}
	&.white {
		color: $white;
	}
	@media (max-width: 1600px) {
		font-size: 20px;
		font-weight: 400;
		line-height: 1.5;
	}
	@media (max-width: 768px) {
		font-size: 16px;
		font-weight: 400;
		line-height: 1.5;
	}
} 