
@font-face {
    font-family: 'Effra';
    src: url('../fonts/Effra-Regular.woff2') format('woff2'),
        url('../fonts/Effra-Regular.woff') format('woff');
    font-weight: normal;
}

@font-face {
    font-family: 'Effra';
    src: url('../fonts/Effra-Bold.woff2') format('woff2'),
        url('../fonts/Effra-Bold.woff') format('woff');
    font-weight: bold;
}
@font-face {
    font-family: 'Harir';
    src:url('../fonts/Harir.woff2') format('woff2'),
        url('../fonts/Harir.woff2') format('woff'),
        url('../fonts/Harir.woff2') format('ttf'),
        url('../fonts/Harir.woff') format('svg');
    font-weight: normal;
}
@font-face {
    font-family: 'Harir';
    src:url('../fonts/Harir-Bld.woff2') format('woff2'),
        url('../fonts/Harir-Bld.woff2') format('woff'),
        url('../fonts/Harir-Bld.woff2') format('ttf'),
        url('../fonts/Harir-Bld.woff') format('svg');
        
    font-weight: bold;
}