.partners-tabs {
    background-color: $vistawhite;
    overflow: hidden;
    padding-bottom: 130px;
        @media #{$vptabletP} {
            padding-bottom: 80px;
        }
        @media #{$vpmobile} {
            padding-bottom: 80px;
        }
    .partners-category-wrap {
        .slick-slider {
            margin:0 -16px;
        }
        .slick-slide {
            margin-right:16px;
            margin-left:16px;
        }
        .category-slider2 {
            
            .slick-list {
                overflow: hidden;
                // margin: 0 -16.5px;
                @media (max-width: 1500px) {
                    // margin: 0 -10px;
                }
                @media (max-width: 767px) {
                    width: 100%;
                    // max-width: 90%;
                }
                .slick-slide {
                    // padding: 0 16.5px;
                    @media (max-width: 1500px) {
                        // padding: 0 10px;
                    }
                }
            }
            &.sty-2 {
                .slick-list {
                    overflow: hidden;
                    // margin: 0 -16.5px;
                    @media (max-width: 1500px) {
                        // margin: 0 -10px;
                    }
                   
                }
            } 
            .slider-content {
                .img-icons {
                    position: relative;
                    min-height: 360px;
                    overflow: hidden;
                    border-radius: 8px;
                    @media #{$lgdesktopSmall2} {
                        min-height: 300px;
                    } 
                    img {
                        position: absolute;
                        width: 100%;
                        object-fit: cover;
                        height: 100%;
                    }
                    .logo-category {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        transition: all .25s ease-in-out;
                        opacity: 1;
                        visibility: visible;
                        padding: 25px 30px;
                        .para {
                            font-weight: bold;
                            color: $white;
                            margin-bottom: 0;
                        }
                    }
                    .category-detail {
                        position: absolute;
                        padding: 25px 30px;
                        background-color: rgba($color: $magenta, $alpha: 0.75);
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        transition: all .65s ease-in-out;
                        border-radius: 10px;
                        opacity: 0;
                        visibility: hidden;
                        transform: translateY(100%);
                        color: $white;
                        @media (max-width: 1500px) {
                            padding: 20px 20px;
                        }
                        @media #{$vptabletP} {
                            visibility: visible;
                            opacity: 1;
                            transform: translateY(0);
                        }
                        .sub-para {
                            font-weight: bold;
                            color: $white;
                            &.detail-para {
                                font-weight: normal;
                                padding: 8px 0 133px;
                                color: $white;
                            }
                        }
                        .category-detail-wrapper{
                            position: relative;
                            width: 100%;
                            height: 100%;
                            .explore-link{
                              position: absolute;
                              bottom: 10px;
                              left: 0;
                              width: auto;
                              font-size: 17px;
                              &:hover{
                                svg {
                                    margin-left: 1px;
                                }    
                              }
                              svg {
                                transition: margin 0.25s ease-in-out;
                                width: 13px;
                                margin-left: 10px;
                                /*rtl:raw:transform: scale(-1)*/
                                path,
                                rect,
                                polygon,
                                line {
                                    stroke: $white;
                                }
                              }
                            }
                        }
                    }
                    .logo-category{
                        opacity: 1;
                        transition: all .20s ease-in-out 0.3s;
                        @media #{$vptabletP} {
                            opacity: 0; 
                        }
                    }
                    &:hover  {
                        .logo-category {
                            opacity: 0;
                            visibility: hidden;
                            transition: all .15s ease-in-out;

                        }
                        .category-detail {
                            opacity: 1;
                            visibility: visible;
                            transform: translateY(0);
                        }
                    }
                }
            }
            .slick-dots {
                display: flex;
                justify-content: center;
                margin-top: 50px;
                li {
                    margin: 0 4px;
                    button {
                        width: 15px;
                        height: 15px;
                        text-indent: -9999px;
                        background: $white;
                        border-radius: 50%;
                        border: 1px solid #721c34;
                        display: block;
                        padding: 0;
                        &:focus {
                            outline: none;
                        }
                    }
                    &.slick-active {
                        button {
                            background: $magenta;
                            
                        }
                    }
                }
            }
            .slick-arrow {
                bottom: -50px;
                padding: 0;
                right: 0;
                margin-right: 16px;
                border: none;
                outline: none;
                box-shadow: none;
                left: auto;
                &:hover,&:focus,&:active{
                    border: none;
                    outline: none;
                    box-shadow: none;
                }
                @media #{$vpmobile} {
                    width: 45px;
                    height: 45px;
                    transform: translateX(calc(50% + 30px));
                    right: calc(50% - 0px);
                    margin-right: 0px;
                }
                &.slick-disabled{
                    
                }
                .img,svg {
                    path{
                    }
                }
            }
            .slick-prev{
                right: 50px;
                @media #{$vpmobile} {
                    transform: translateX(calc(50% - 30px));
                    right: calc(50% - 0px);
                }
            }
        }
    }
}