.video-box {
    .video-js {
        width: 100%;
        height: auto;
        padding-top: 40%;
        @media #{$vpmobile} {
            height: 500px;
        }
        @media #{$vpmobile} {
            height: 459px;
        }
        .vjs-tech{
            object-fit: cover;
        }
        .vjs-big-play-button {
            border: none;
            background-color: transparent;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &.vjs-paused {
            position: relative;
        }
        .vjs-poster {
            background-image: url(../img/thumbnails/video-thumb.jpg) !important;
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
        }
        &.vjs-ended {
            .vjs-poster {
                display: block;
            }
            .vjs-big-play-button {
                display: block;
            }
        }
    }
}
.video-js .vjs-big-play-button .vjs-icon-placeholder {
    &::before{
        content: "";
        background-image: url(../img/icon/ic-play.svg);
        background-size: contain;
        width: 104px;
        height: 104px;
        background-repeat: no-repeat;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: background-image 0.25s ease-in-out;
        @media #{$vpmmobile} {
            width: 70px;
            height: 70px;
            background-size: contain;
        }
    }
    &:hover{
        &::before{
            background-image: url(../img/icon/ic-play-hover.svg);
        }
    }
}
.video-js .vjs-control-bar{
    background-color: rgba(114, 28, 52, 0.7);
}
.video-js .vjs-load-progress div{
    background-color: rgba(221, 199, 166, 0.5);
}
.vjs-paused{
    .vjs-big-play-button{
        display: block;
    }
    .vjs-control-bar{
        display: none;
    }
}

.vjs-loading-spinner{
    border: 0px solid rgba(43, 51, 63, 0);
}
.vjs-loading-spinner:after, .vjs-loading-spinner:before{
    left: 0;
}
