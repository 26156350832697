.register-header-wrapper{
    .register-header-sec{
        padding: 30px 60px;
        background-color: $magenta;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 60px;
        @media #{$vpdesktop} {
            padding: 20px 36px;
        }
        // max-width 991px
        @media #{$vptabletP} {
            padding: 15px 20px;
        }
        @media #{$vpmobile} {
            padding: 15px 20px;
        }
        .logo-sec{
            flex: 0 0 282px;
			max-width: 282px;
            display: flex;
            align-items: center;
            position: relative;
            z-index: 3;
            @media #{$vpdesktop} {
                flex: 0 0 255px;
                max-width: 255px;
            }
            @media #{$vptabletL} {
                img,svg{
                    // height: 50px;
                    width: 100%;
                }
            }
            @media #{$vptabletP} {
                flex: 0 0 69%;
			    max-width: 239px;
            }
            @media #{$vpxsmobileSmall} {
                flex: 0 0 69%;
			    max-width: 69%;
            }
            img,svg{
                height: auto;
                width: 100%;
            }
        }
        .help-sec{
            display: flex;
            justify-content: flex-end;
            flex-grow: 1;
            .sub-para{
                span{
                    font-weight: bold;
                }
                .number-span-help-sec{
                    font-weight: normal;
                    margin-left: 5px;
                    
                    position: relative;
                    /*rtl:raw:text-align: right;*/
                    /*rtl:raw:direction: ltr;*/
                    /*rtl:raw:font-family: 'Effra';*/
                    /*rtl:raw:top: -5px;*/
                }
            }
            a{
                color: $white;
                transition: color 0.3s ease;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                /*rtl:raw:align-items: flex-start;*/
                &:hover{
                    color: $beige;
                    img,svg{
                        path,
                        polygon,
                        rect{
                            fill: $beige;
                        }
                    }
                }
                img,svg{
                    width: 21px;
                    margin-left: 9px;
                    position: relative;
                    /*rtl:raw:top: -2px;*/
                    path,
                    polygon,
                    rect{
                        fill: $white;
                        transition: color 0.3s ease;
                    }
                }
            }
            .desktop-txt{
                display: block;
                @media #{$vpmobile} {
                    display: none;
                }
            }
            .mbl-txt{
                display: none;
                color: $white;
                @media #{$vpmobile} {
                    display: block;
                }
            }
        }
    }    
}
.step-bar{
    background-color: $beige;
    width: 25%;
    height: 7px;
    display: block;
    transition: width 0.35s linear;
    border-top: 0.5px solid #e4ff0040;
    @media #{$vptabletP} {
        height: 4px;
    }
}