.sec-banner-slider {
    .main-slider-section {
        .slider-content {
            .slider-img {
                position: relative;
                .content-wrap {
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.59) 0%, rgba(0,212,255,0) 31%);
                    &.js-minHeight{
    
                        @media #{$vptabletPro} {
                            min-height: 520px;
                        }
                    }
                }
                &.wa-lanfing-banner{
                    .nav-slider-img{
                        z-index: 0;
                    }
                    .content-wrap{
                        position: relative;
                        @media #{$lgdesktop} {
                        padding: 0;
                        }
                        .banner-heading{
                            &.sty1 {
                               padding: 350px 0 269px;
                               @media #{$vpmobile} {
                                padding: 192px 0 119px;
                                //max-width: 100%;
                                .para{
                                    margin: 13px 0 23px !important;
                                }
                            }
                            // .banner-content-wrap {
                            //     @media #{$vpmobile} {
                            //         padding-left: 22px;
                                    
                            //     }
                            // }
                            }
                            //padding: 397px 0 255px;
                            padding: 406px 0 284px;
                            //max-width: 40%;
                            @media #{$vpdesktop} {
                               // max-width: 60%;
                            }
                            @media #{$vptabletL} {
                               // max-width: 80%;
                            }
                            @media #{$vpmobile} {
                                padding: 192px 0 154px;
                                //max-width: 100%;
                            }
                            .banner-content-wrap {
                                .para {
                                    padding: 0;
                                    margin: 5px 0 20px;
                                }
                            }
                        }
                    }
                }
                &.sec-banner-wrap{
                    .content-wrap{
                        
                        .banner-heading{
                            padding: 120px 0;
                           max-width: 587px;
                            @media #{$vptabletP} {
                                padding: 80px 0px;
                            }
                            @media #{$vpmobile} {
                                padding: 60px 0px;
                            }
                        }
                        .para{
                            padding-left: 0;
                        }
                    }
                }

                .nav-slider-img {
                    position: absolute;
                    z-index: 0;
                    width: 100%;
                    height: 100%;
                    picture {
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .nav-slider-img-wrap {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        &.nav-slider-img-wrap--craftPerformanceDetail{
                            &::after{
                                background-color: rgba(41,40,38,0.4);
                            }
                        }
                        &.nav-slider-img-wrap-inner-opactiy{
                            &::after{
                                background-color: rgba(0, 0, 0, 0);
                            }
                        }
                        picture {
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        &::after {
                            content: "";
                            background-color: rgba(0, 0, 0, 0.34);
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
                .content-wrap {
                    width: 100%;
                    height: 100%;
                    max-width: 100%;
                    margin: 0 auto;
                    padding: 0px 0px;
                    display: flex;
                    align-items: center;
                    position: relative;
                    z-index: 1;
                    
                    @media (max-width: 1500px) {
                        padding: 80px 0px 80px;
                    }
                    @media #{$vptabletL} {
                        padding: 0px 0px;
                    }
                    @media #{$vptabletP} {
                        padding: 0px 0px;
                    }
                    @media #{$vptabletPro} {
                        padding: 193px 0px 92px 0px;
                    }
                    &.content-wrap--craftPerformanceDetail{
                        padding: 100px 0 131px 0;
                        min-height: 852px;
                        align-items: center;
                        @media (max-width: 768px) {
                            padding: 81px 0px 181px 0px;
                            min-height: 727px;
                        }
                        .banner-heading{
                            max-width: 725px;
                            padding-top: 0;
                            p{
                                margin-top: 34px;
                            }
                        }
                        &.programs-banner {
                            align-items: flex-end;
                            padding: 100px 0 40px;
                            height: 520px;
                            min-height: 80%;
                            @media (max-width: 1023px) {
                                padding: 133px 0 45px;
                                min-height: auto;
                            }
                        }
                     }
                    &.inner-banner-style{
                        padding: 100px 0 40px 0;
                        height: 520px;
                        max-height: 80%; 
                        align-items: flex-end;
                        @media #{$vptabletPro} {
                            padding: 133px 0px 45px 0px;
                            min-height: auto;
                        }
                        .banner-heading{
                            padding-top: 0;
                            max-width: 805px;
                        }
                        .banner-artisan-contact {
                            margin-top: 40px;
                            display: flex;
                            justify-content: space-between;
                            @media (max-width:1599px) {
                                flex-direction: column;
                                justify-content: flex-start;
                            }
                            @media (max-width:991px) {
                                margin-top: 10px;
                            }
                            @media (max-width:370px) {
                                margin-top: 0px;
                            }
                            .get-in-touch {
                                display: flex;
                                align-items: center;
                                flex: 0 0 40%;
                                max-width: 40%;
                                @media (max-width:1599px) {
                                    flex: 0 0 100%;
                                    max-width: 100%;
                                }
                                @media (max-width:991px) {
                                    flex-direction: column;
                                    margin-bottom: 30px;
                                    align-items: flex-start;
                                }
                                @media (max-width:370px) {
                                    margin-bottom: 10px;
                                }
                                p {
                                    color: #ffffff;
                                    font-size: 15px;
                                    font-weight: 700;
                                    text-transform: uppercase;
                                    margin-right: 10px;
                                }
                                .banner-contact {
                                    @media (max-width:370px) {
                                        display: flex;
                                        flex-direction: column;
                                    }
                                    a {
                                        color: #CFBFA7;
                                        margin: 0 20px;
                                        @media (max-width:1700px) {
                                            margin: 0 10px;
                                        }
                                        @media (max-width:991px) {
                                            &:first-child {
                                                margin-left: 0;
                                            }
                                        }
                                        @media (max-width:767px) {
                                            font-size: 15px;
                                        }
                                        @media (max-width:370px) {
                                            margin: 5px 0;
                                        }
                                        svg {
                                            margin-right: 20px;
                                            @media (max-width:1700px) {
                                                margin-right: 10px;
                                            }
                                            path {
                                                fill: #CFBFA7;
                                            }
                                        }
                                    }
                                }
                            }
                            .banner-filters {
                                display: flex;
                                flex: 0 0 60%;
                                max-width: 60%;
                                justify-content: flex-end;
                                align-items: center;
                                @media (max-width:1599px) {
                                    flex: 0 0 100%;
                                    max-width: 100%;
                                    justify-content: flex-start;
                                }
                                @media (max-width:991px) {
                                    flex-direction: column-reverse;
                                }
                                .filter-wrapper {
                                    flex: 0 0 25%;
                                    max-width: 25%;
                                    margin: 0 9px;
                                    @media (max-width:991px) {
                                        flex: 0 0 100%;
                                        max-width: 100%;
                                        margin: 0;
                                        width: 100%;
                                    }
                                    .form-group-padding {
                                        margin-top: 0;
                                    }
                                    .input-text-label {
                                        color: #292826;
                                        font-size: 15px;
                                        font-weight: 400;
                                    }
                                    .select2-container--default .select2-selection--single {
                                        border-radius: 12px !important;
                                    }
                                    // .select2-container--default {
                                    //     .select2-selection--single {
                                    //         .select2-selection__rendered {
                                    //             min-height: 54px;
                                    //         }
                                    //     } 
                                    // }
                                }
                                .join-us-form {
                                     flex: 0 0 38%;
                                     max-width: 38%;
                                     margin: 0 9px;
                                     @media (max-width:991px) {
                                        flex: 0 0 100%;
                                        max-width: 100%;
                                        margin: 0;
                                        width: 100%;
                                        margin: 9px 0;
                                     }
                                   .form-group {
                                       input {
                                            width: 100%;
                                            height: 45px;
                                            border-radius: 12px;
                                            padding: 9px 20px;
                                            border-color: #ffffff;
                                            border: none;
                                            &::placeholder {
                                                color: #292826;
                                            }
                                       }
                                       .btn-gold {
                                            position: absolute;
                                            top: 0;
                                            right: 0;
                                            background: #CFBFA7;
                                            color: #FCFBFA;
                                            font-size: 15px;
                                            width: 130px;
                                            height: 45px;
                                            text-align: center;
                                            border-radius: 12px;
                                            @media (max-width:767px) {
                                                width: 110px;
                                            }
                                       }
                                   } 
                                }
                            }
                        }
                     }
                     &.inner-banner-style-1{
                        padding: 100px 0 93px 0;
                        height: 520px;
                        max-height: 80%;
                        align-items: flex-end;
                        @media #{$vptabletPro} {
                            padding: 133px 0px 45px 0px;
                            min-height: auto;
                        }
                     }
                    .banner-heading {
                        padding-top: 130px;
                        max-width: 575px;
                        @media #{$vptabletPro}{
                            padding-top: 0px;
                        }
                        .heading1 {
                            position: relative;
                            color: $white;
                            text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);                           
                        }
                        .para {
                            color: $white;
                            text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                            margin: 5px 0 20px;
                            @media #{$vptabletP} {
                                margin-bottom: 13px;  
                            }
                        }                 
                    }
                    button{
                        display: block;
                    }
                    &--second-view{
                        .banner-heading{
                            h1{
                                &::before{
                                            position: absolute;
                                            display: inline-block;
                                            content: "";
                                            border-left: 18px solid $magenta;
                                            width: 50px;
                                            height: 80%;
                                            top: 17%;                                    
                                            transform: translateY(-1rem);
                                            left: -5%;
                                        }  
                            }
                        }
                        button{
                            display: none;
                        }
                    }
                }
            }
        }
        .slick-dots {
            display: flex;
            position: absolute;
            bottom: 25px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            max-width: 150px;
            justify-content: space-between;
            @media #{$vpmobile} {
                justify-content: space-around;
                // bottom: 0;
            }
        }
        li {
            button {
                width: 15px;
                text-indent: -9999px;
                height: 15px;
                background: $magenta;
                border-radius: 50%;
                border: none;
                padding: 0;
            }
            &.slick-active {
                button {
                    background: $white;
                    &:focus {
                        outline: none;
                    }
                }
            }
            @media (max-width: 475px) {
                width: 50px;
            }
        }
    }
}

.artisanDetail-search {
    background: #FCFBFA;
    padding: 50px 0;
    @media (max-width:991px) {
        overflow-x: scroll;
    }
    .bar-wrapper {
        display: flex;
        background-color: #ffffff;
        border: 1px solid #E2E7F2;
        border-radius: 15px;
        padding: 18px 36px;
        position: relative;
        margin-top: 12px;
        @media (max-width: 991px) {
            background-color: transparent;
            border: none;
            padding: 0;
        }
        &.isActive {
            border-radius: 15px 15px 0 0;
            border-bottom: 0;
            .dropdown-btn-wrapper {
                .dropdown-btn {
                    &.btnActive {
                        background: #CFBFA7;
                        color: #ffffff;
                        svg {
                            transform: rotate(180deg);
                            path {
                                stroke: #ffffff;
                            }
                        }
                    } 
                }
            }
        }
        .bar-detail {
            display: flex;
            flex: 0 0 25%;
            max-width: 25%;
            align-items: center;
            @media (max-width: 991px) {
                flex: 0 0 30%;
                max-width: 30%;
                background-color: #ffffff;
                border: 1px solid #E2E7F2;
                border-right: 0;
                padding-top: 18px;
                padding-bottom: 18px;
                padding-left: 36px;
            }
            @media (max-width: 767px) {
                flex: 0 0 40%;
                max-width: 40%;
            }
            @media (max-width: 500px) {
                flex: 0 0 60%;
                max-width: 60%;
            }
            span {
                width: 36px;
                height: 36px;
                min-width: 36px;
                border-radius: 50%;
                color: #ffffff;
                background-color: #CFBFA7;
                font-size: 15;
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 24px;
                font-family: 'Effra' !important;
            }
            p {
                color: #292826;
                font-size: 15px;
                font-weight: 700;
            }
        }
        &.sty1 {
            background: transparent;
            border: none;
            align-items: center;
            margin-top: 0;
            padding-top: 0;
            p {
                color: #CFBFA7;
                font-size: 15px;
                font-weight: 700;
                text-transform: uppercase;
            }
            .bar-detail {
                flex: 0 0 24%;
                max-width: 24%;  
                background: transparent;
                border: none;
                padding: 0;
                @media (max-width:991px) {
                    flex: 0 0 30%;
                    max-width: 30%;  
                }
                @media (max-width:767px) {
                    flex: 0 0 43%;
                    max-width: 43%;  
                }
                @media (max-width:500px) {
                    flex: 0 0 63%;
                    max-width: 63%;  
                }
            }
            .dropdown-detail {
                flex: 0 0 27%;
                max-width: 27%; 
                background: transparent;
                border: none;
                padding: 0;
                @media (max-width:1599px) {
                    flex: 0 0 35%;
                    max-width: 35%;
                }
                @media (max-width:1199px) {
                    flex: 0 0 38%;
                    max-width: 38%;
                }
                @media (max-width:991px) {
                    flex: 0 0 50%;
                    max-width: 50%;  
                }
                @media (max-width:767px) {
                    flex: 0 0 70%;
                    max-width: 70%;  
                }
                @media (max-width:500px) {
                    flex: 0 0 100%;
                    max-width: 100%;  
                }
            }
            .social-links-wrapper {
                padding-left: 170px;
                background: transparent;
                border: none;
                padding-top: 0;
                padding-bottom: 0;
                @media (max-width:1599px) {
                    padding-left: 70px;
                }
                @media (max-width:1199px) {
                    padding-left: 45px;
                }
            }
        }
    }
    .dropdown-detail {
        flex: 0 0 23%;
        max-width: 23%;
        justify-content: space-between;
        @media (max-width:1599px) {
            flex: 0 0 30%;
            max-width: 30%;
        }
        @media (max-width: 991px) {
            flex: 0 0 40%;
            max-width: 40%;
            background-color: #ffffff;
            border: 1px solid #E2E7F2;
            border-left: 0;
            border-right: 0;
            padding-top: 18px;
            padding-bottom: 18px;
            padding-left: 36px;
        }
        @media (max-width: 767px) {
            flex: 0 0 60%;
            max-width: 60%;
        }
        @media (max-width: 500px) {
            flex: 0 0 80%;
            max-width: 80%;
        }
        .dropdown-btn-wrapper {
            display: flex;
            justify-content: space-between;
            height: 100%;
            align-items: center;
            .dropdown-btn {
                background: transparent;
                border: 1px solid #CFBFA7;
                border-radius: 30px;
                color: #292826;
                display: flex;
                align-items: center;
                font-size: 15px;
                padding: 8px 15px;
                transition: all 0.35s ease;
                font-family: 'Effra' !important;
                svg {
                    margin-left: 30px;
                    transition: all 0.35s ease;
                    path {
                        stroke: #292826;
                    }
                }
                &:hover {
                    background-color: #CFBFA7;
                    color: #ffffff;
                    svg {
                        path {
                            stroke: #ffffff;
                        }
                    }
                }
            }
        }
        .licenses-detail {
            position: absolute;
            left: 0;
            width: 100%;
            bottom: -65px;
            padding: 0;
        }
    }
    .social-links-wrapper {
        display: flex;
        align-items: center;
        padding-left: 185px;
        @media (max-width:1599px) {
            padding-left: 85px;
        }
        @media (max-width: 991px) {
            flex: 0 0 60%;
            max-width: 60%;
            background-color: #ffffff;
            border: 1px solid #E2E7F2;
            border-left: 0;
            padding-top: 18px;
            padding-bottom: 18px;
        }
        @media (max-width: 767px) {
            flex: 0 0 75%;
            max-width: 75%;
            padding-left: 90px;
        }
        @media (max-width: 500px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .social-icon {
            padding: 0 27px;
            @media (max-width:1199px) {
                padding: 0 20px;
            }
            @media (max-width:767px) {
                padding: 0 15px;
            }
            a {
                svg {
                    path {
                        transition: all 0.35s ease;
                    }
                }
                &:hover {
                    svg {
                        path {
                            fill: #CFBFA7;
                        }
                    }
                }
            }
        }
    }
}
.loadeMore--btn {
    text-align: center;
    margin-top: 30px;
    a {
        text-align: center;
    }
}
.loadeMore--loader {
    text-align: center; 
    margin-top: 30px;
    img {
        width: 40px;
        height: 40px;
    }
}
.liscence-detail-box {
    background-color: #ffffff;
    border: 1px solid #E2E7F2;
    border-radius:0 0 15px 15px;
    display: none;
    transition: all 0.35s ease;
    overflow: hidden;
    @media (max-width:767px) {
        width: calc(100% + 100px);
    }
    &.active {
        display: block;
    }
    .detail-title {
        p {
            font-size: 15px;
            font-weight: 700;
            color: #CFBFA7;
            margin: 24px 0;
            text-transform: uppercase;
            @media (max-width:767px) {
                margin-bottom: 50px;
            }
        }
    }
    .slick-list {
        @media (max-width:767px) {
            overflow: hidden !important;
            max-width: 100% !important;
        }
    }
    .slick-track {
        display: flex;
        margin: 0;
        .slick-slide {
            height: auto;
            opacity: 0.5;
            &.slick-active {
                opacity: 1;
            }
        }
    }
    .js-licenses-slider {
        .slick-arrow {
            text-indent: -9999px;
            top: -45px;
            right: 100px;
            z-index: 1;
            width: 99px;
            height: 28px;
            background-size: contain;
            &.slick-prev {
                background-image: url(../img/icon/arrow-back.svg);
                right: 180px;
            }
            &.slick-next {
                background-image: url(../img/icon/arrow-forward.svg);
            }
            &:focus {
                outline: none;
            }
            @media (max-width: 991px) {
                width: 56px;
                height: 20px;
                top: -40px;
                right: 50px;
                &.slick-prev {
                    right: 150px;
                }
            }
            @media (max-width: 767px) {
                top: -35px;
                right: auto;
                left: 85px;
                &.slick-prev {
                    right: auto;
                    left: 8px;
                }
            }
        }
    }
    .gallery-sec {
        .gs-wrapper {
            .gsw-slider {
                overflow: visible;
            }
        }
    }
}

.banner-artisan-contact .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #292826;
}  
.sec-banner-slider .main-slider-section .banner-artisan-contact .banner-filters .select2-results__options li {
    width: 90% !important;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}
.sec-banner-slider .main-slider-section .slider-content .slider-img .nav-slider-img .nav-slider-img-wrap::after {
    background-color: rgba(41,40,38,0.4);
}
[dir="rtl"] .js-licenses-slider {
    .slick-arrow {
        &.slick-prev {
            background-image: url(../img/icon/arrow-forward.svg);
        }
        &.slick-next {
            background-image: url(../img/icon/arrow-back.svg)
        }
    }
}