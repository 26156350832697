.header {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    z-index: 8;
    transition: all 0.35s ease 0s;

    // @media #{$vptabletPro} {
    //     position: absolute;
    //     top: 0;
    //     padding: 21px 16px 21px 16px;
    //     background-color: $white;
    //     z-index: 10020;
    // }
// .scroll-down{
//     .header {
//         top: -90px;
//     }
// }
.header-wrap {
    @media #{$vptabletPro} {
        margin: 0;
    }

    .header-wraper {
        display: flex;
        // padding: 0 24px 0 24px;
        padding: 35px 60px;
        align-items: center;
        // height: 60px;

        @media #{$vpdesktop} {
            padding: 35px 36px;
        }
        // max-width 991px
        @media #{$vptabletP} {
            padding: 20px 20px;
        }
        @media #{$vpmobile} {
            padding: 20px 20px;
        }

        .header-logo {
            flex: 0 0 282px;
			max-width: 282px;
            display: flex;
            align-items: center;
            position: relative;
            z-index: 3;
            @media #{$vpdesktop} {
                flex: 0 0 255px;
                max-width: 255px;
            }
            img,svg{
                height: auto;
                width: 100%;
            }
            &.heder-logo-clr-change{
                max-width: 211px;
                flex: 0 0 211px;
                @media #{$vpxsmobileSmall} {
                    flex: 0 0 57%;
                    max-width: 57%;
                }
                img,svg{
                    height: 40px;
                    path{
                        fill: $magenta;
                    }
                }
            }
            
            a {
                display: flex;
            }
			img {
				// height: 25px;
				// width: auto;
                @media #{$vptabletPro} {
                    // height: 20px;
                }
			}
			
            @media #{$vptabletPro} {
                // flex: 0 0 230px;
                // max-width: 230px;
            }
            @media #{$vpdesktop} {
                // flex: 0 0 319px;
			    // max-width: 319px;
            }
            @media #{$vptabletL} {
                img,svg{
                    // height: 50px;
                    width: 100%;
                }
            }
            @media #{$vptabletP} {
                flex: 0 0 69%;
			    max-width: 239px;
            }
            @media #{$vpxsmobileSmall} {
                flex: 0 0 69%;
			    max-width: 69%;
            }
        }

        .header-menu {
            flex-grow: 1;
            justify-content: flex-end;
            align-items: center;
            display: flex;
        }

        .header-menu-wraper {
            display: flex;
            @media #{$vptabletPro} {
                flex-direction: column;
                position: fixed;
                background: $white;
                top: 0;
                left: 0;
                width: 100%;
                height: 0%;
                opacity: 0;
                visibility: hidden;
                transition: opacity .35s, visibility .35s, height .35s;
                overflow: hidden;
                justify-content: flex-start;
                align-items: flex-end;
                padding: 142px 24px 38px 18px;

                &.open {
                    opacity: 1;
                    visibility: visible;
                    height: 100%;
                    overflow: hidden;
                    z-index: 2;
                    min-height: 335px;

                    .menu-wraper {
                        &.js-hid {
                            opacity: 1;
                        }
                    }
                }
            }

            .menu-wraper {
                flex-grow: 1;
                display: flex;
                align-items: center;

                @media #{$vptabletPro} {
                    display: block;
                    flex-grow: 0;
                    opacity: 0;
                    width: 100%;
                }
                ul {
                    display: flex;
                    align-items: center;

                    @media #{$vptabletPro} {
                        display: block;
                    }

                    li {
                        padding-right: 25px;
                        margin-bottom: 0px;
                        // max-width 1700px
                        // @media #{$vpMddesktop} {
                        //     padding-right: 50px;
                        //     margin-bottom: 0px;
                        // }
                        // max-width 1400px
                        @media #{$vpdesktop} {
                            padding-right: 20px;
                            margin-bottom: 0px;
                        }
                        @media #{$vptabletP} {
                            padding-right: 0px;
                            margin-bottom: 0px;
                        }
                        &:last-child {
                            padding-right: 0px;
                        }
                        &.lang-changer{
                            @media #{$vptabletP}{
                                position: absolute;
                                /*rtl:ignore*/
                                top: 32px;
                                /*rtl:ignore*/
                                /*rtl:raw:top: 32px;*/
                                right: 67px;
                                padding: 0;
                                margin: 0;

                                a{
                                    padding: 0;
                                }
                            }
                            a{
                                &.en-font{
                                    line-height: 13px;
                                    min-height: 31px;
                                }
                                &.ar-font{
                                    line-height: 14px;
                                    min-height: 10px;
                                }
                                &:before{
                                    @media #{$vptabletP}{
                                        transform: scaleX(0);
                                        width: 0;
                                        background-color: transparent;
                                    }
                                }
                            }
                        }
                        a {
                            color: $white;
                            font-weight: bold;
                            position: relative;
                            text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                            transition: all 0.35s ease 0s;
                            display: inline-block;
                            padding-bottom: 0px;
                            margin-bottom: 0px;
                            /*rtl:raw:padding-top: 7px;*/
                            @media #{$vptabletP} {
                                color: $darkgray;
                                text-shadow: 0 0px 0px rgba(0, 0, 0, 0);
                                display: block;
                                padding-bottom: 19px;
                                margin-bottom: 19px;
                            }
                            @media (max-width: 1400px) {
                                font-size: 15px;
                            }
                            &:before {
                                content: '';
                                position: absolute;
                                width: 100%;
                                left: 0;
                                height: 3px;
                                bottom: -14px;
                                transform: scaleX(0);
                                transform-origin: left;
                                transition: all 0.35s ease 0s;
                                background-color: $iPink;

                                @media #{$vptabletPro} {
                                        transform: scaleX(1);
                                        background-color: $darkgray;
                                        bottom: 0px;
                                        height: 0.5px;
                                }
                            }

                            &:hover {
                                color: $iPink;
                                text-shadow: 0 0px 0px rgba(0, 0, 0, 0);
                                &:before {
                                    transform: scaleX(1);
                                }
                            }
                            // @media #{$vptabletPro} {
                            //     font-size: 24px;
                            //     line-height: 1.33;
                            //     font-family: 'Supreme LL Bold Flat';
                            //     display: block;
                            //     padding: 8px 0;

                            //     &:hover {
                            //         opacity: 0.6;
                            //     }

                            //     &:before {
                            //         transform: scaleX(1);
                            //     }
                            // }
                        }

                        &.active {
                            a {
                                text-shadow: 0 0px 0px rgba(0, 0, 0, 0);
                                color: $iPink;
                                &:before {
                                    transform: scaleX(1);
                                }
                            }
                        }
                    }

                }
                .btn-register-mbl{
                    position: absolute;
                    bottom: 20px;
                    left: 24px;
                    width: 100%;
                    display: none;
                    @media #{$vptabletP} {
                        display: block;
                    }
                }
            }
        }
    }
}

.hamburger-menu {
    display: none;
    position: relative;
    cursor: pointer;
    -webkit-transition: opacity .25s ease;
    -o-transition: opacity .25s ease;
    transition: opacity .25s ease;
    z-index: 1;
    margin: 0;
    width: 35px;
    height: 21px;
    z-index: 3;
    margin: 0 0 0 auto;
    padding: 0px 0 0 0;

    @media #{$vptabletPro} {
        display: block;
    }

    &:hover {
        opacity: 1;
    }

    span {
        position: absolute;
        top: 0;
        left: 0;
        height: 2px;
        width: 35px;
        border: none;
        display: block;
        cursor: pointer;
        border-radius: 16px;
        background: $white;
        transition: all .35s ease;
        &:nth-of-type(2) {
            top: 10px;
        }

        &:nth-of-type(3) {
            top: 20px;
        }
    }

    &.active {
        transform: scale(0.8);
            top: 0px;
        span{
            background: $darkgray;
        }
        .top {
            transform: translateY(10px) translateX(0) rotate(45deg);
            top: -4px;
            left: 1px;
        }

        .middle {
            opacity: 0;
        }

        .bottom {
            transform: translateY(-12px) translateX(0) rotate(-45deg);
            top: 18px;
            left: 0px;
        }
    }
}
    &.fixed{
        position: fixed;
        background-color: $white;
        z-index: 8;
        .header-wrap {
            .header-wraper{
                padding-top: 20px;
                padding-bottom: 20px;
                // max-width 991px
                @media #{$vptabletP} {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
                @media #{$vpmobile} {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
                .header-menu-wraper{
                    .menu-wraper{
                        ul{
                            li{
                                a{
                                    color: $darkgray;
                                    text-shadow: 0 0px 0px rgba(0, 0, 0, 0);
                                    &:hover{
                                        color: $iPink;
                                    }
                                }
                                &.active{
                                    a{
                                        color: $iPink;
                                    }
                                }
                            }
                        }
        
                    }
                }
            }
        }
        .hamburger-menu{
            span{
                background-color: $darkgray;
            }   
        }
        .header-logo{
            img,svg{
                path{
                    fill: $darkgray;
                }
            }
        }
    }
}
.header{
    .header-wraper{
        .header-menu{
            .header-menu-wraper{
                .menu-wraper{
                    ul{
                        li{
                            .link{
                                &.register{
                                    border-radius: 6px;
                                    border: 2px solid $magenta;
                                    background-color: $magenta;
                                    color: $white;
                                    /*rtl:remove*/
                                    padding: 11px 23px;
                                    /*rtl:remove*/
                                    /*rtl:raw:padding: 14px 23px 8px 23px;*/
                                    @media (max-width: 991px){
                                        color: $white;
                                    }
                                    @media #{$vptabletPro} {
                                        max-width: 195px;
                                    }
                                    &:hover{
                                        color: $white;
                                        text-shadow: none;
                                        border-color: $errrorClr;
                                        background-color: $errrorClr;
                                        &:before {
                                            transform: scaleX(0);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.fixed{
        .header-wraper{
            .header-menu{
                .header-menu-wraper{
                    .menu-wraper{
                        ul{
                            li{
                                .link{
                                    &.register{
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .menu-wraper-ul {
        li {
            &.lang-changer {
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    left: -13px;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: #ffffff;
                    width: 2px;
                    height: 100%;
                    @media (max-width: 1024px) {
                        display: none;
                    }
                }
            }
        }
    }
    &.fixed {
        .menu-wraper-ul {
            li {
                &.lang-changer {
                    &:after {
                        background-color: #000000;
                        @media (max-width: 1024px) {
                            display: none;
                        }
                    }
                }
            }
        }      
    }
}
