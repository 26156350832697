.traditional-craft-main-wrapper{
    background-color: $lightbeige;
    .traditional-craft {
        background-color: $white;
        background-image: url('../img/background/bg-traditional.png');
        background-position: left 31px top 21px;
        background-size: 56% calc(100% - 42px);
        background-repeat: no-repeat;
        @media #{$lgdesktop} {
            background-size: 56% calc(100% - 42px);
        }
        @media #{$vptabletL} {
            background-size: 58% calc(100% - 42px);
        }
        @media #{$vptabletP} {
            background-size: cover;
            background-position: 0;
        }
        @media #{$vpmobile} {
            border-radius: 8px;
        }
        .sec-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            @media #{$lgdesktop} {
                justify-content: space-between;
            }
            @media #{$vptabletP} {
                flex-direction: column-reverse;
            }
            .craft-img-wrap {
                display: flex;
                justify-content: space-between;
                max-width: 54%;
                flex: 0 0 54%;
                @media #{$vptabletL} {
                    max-width: 55.6%;
                    flex: 0 0 55.6%;
                }
                @media #{$vptabletP} {
                    max-width: 100%;
                    flex: 0 0 100%;
                    width: 100%;
                }
                @media #{$vpmobile} { 
                    flex-direction: column;
                    max-width: 100%;
                    flex: 0 0 100%;
                    width: 100%;

                }
                .card {
                    border: none;
                    height: 100%;
                    background-color: transparent;
                    border-radius: 8px;
                    overflow: hidden;
                    position: relative;
                    margin-right: 18px;  
                    margin-bottom: 0;
                    height: 100%;
                    width: 100%;
                    padding-top: 63.6%;
                    @media #{$vpmobile} {
                        padding-left: 0px;
                        margin-bottom: 30px;
                        padding-right: 0;
                        margin-right: 0px;
                        height: 458px;
                        
                    }
                    img{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                    }
                    &:hover{
                        .card-body{
                            .sub-title {
                                opacity: 0.7;
                            }
                        }
                    }
                    .card-img-top{
                        object-fit: cover;
                        height: 100%;
                    }
            
                    .card-body {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        padding: 30px;
                        @media #{$lgdesktop} {
                            padding: 20px;
                        }
                        @media #{$vptabletP} {
                            padding: 30px;
                        }
                        .sub-title {
                            font-weight: bold;
                            color: $white;
                            margin-bottom: 0;
                            transition: opacity 0.25s ease-in-out;
            
                            @media #{$lgdesktop} {
                                font-size: 30px;
                            }
                            @media #{$vptabletL} {
                                font-size: 25px;
                            }
                            @media #{$vpmobile} {
                                font-size: 32px;
                            }
                        }
                    }
                }
            }
        
            .aside {
                display: flex;
                align-items: center;
                height: 100%;
                padding-left: 94px;
                // max-width: 33%;
                flex-grow: 1;
                // @media #{$vptabletL} {
                //     max-width: 40%;
                //     flex: 0 0 40%;
                // }
                @media #{$vptabletP} {
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding-bottom: 25px;
                    padding-left: 0;
                    display: block;
                    width: 100%;
                }
                // @media #{$vpmobile} {
                //     width: 100%;
                // }
                .card-body {
                    position: relative;
                    .para {
                        padding: 44px 0 40px;
                    }
                }
                .card-body-content{
                    margin-top: 16px;
                }
        
                @media #{$lgdesktop} {
                    .card-body {
                        padding-left: 0px;
                        .heading2 {
                            font-size: 50px;
                        }
        
                        &::after {
                            height: 110px;
                        }
        
                        .para {
                            padding: 14px 0 10px;
                            font-size: 25px;
                        }
                    }
                }
        
                @media #{$vptabletL} {
        
                    .card-body {
                        .heading2 {
                            font-size: 40px;
                        }
        
                        &::after {
                            height: 90px;
                            width: 6px;
                            top: 25px;
                        }
                    }
                }
        
            }
            .cta{
                padding-top: 15px;
                @media #{$vpmobile} {
                    padding-top: 0px;
                    text-align: center;
                }   
            }
        }
    }
}