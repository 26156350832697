// Custom Variables //

$primaryFont: 'Effra';
$primaryArabicFont: 'Harir';

// Set Colors
$black: #000000;
$white: #ffffff;
$vistawhite: #FAF9F4;
$darkgray: #383838;
$lightgray: #767676;
$grayssty3: #B1B1B1;
$grayssty4: #E5E5E5;
$grayssty5: #F5F5F5;
$magenta: #721C34;
$lightmagenta: #C45270;
$orange: #EB592C;
$lightorange: #F47045;
$green: #4CB097;
$lightgreen: #99D1B0;
$purple: #7F5996;
$lightpurple: #8E70AF;
$beige: #DEC7A6;
$lightbeige: #f8f5eb;
$placehoderClr: #A09E9E;
$errrorClr: #C55271;
$grayssty6: #FAF9F4;
$disclaimer: #808080;
$bgFormWrapper:#FAFAFA;
$lightMidGold: #EFE8DE;
$goldVariant: #CFBFA7;


// Old Colors
$iPrimary: #100449;
$iGray: #8f8f8f;
$iBlack: #3c3f40;
$iYellow: #fdc340;
$iGreen: #75b349;
$iBlue: #009fdc;
$iMagenta: #b9287e;
$gray: #383838;
$magenta: #721C34;
$beige: #DDC7A6;
$lightgray: #f5f5f5;
$iPink: #C55271;


// Media Sizes
$vpdesktopMin: "(min-width:2100px)";
$vpdesktopSmall: "(max-width:1919px)";
$vpMddesktop: "(max-width:1700px)";
$lgdesktop: "(max-width:1600px)";
$vpdesktop: "(max-width:1400px)";
$vptabletL: "(max-width:1229px)";
$vptabletP: "(max-width:991px)";
$vpmobile: "(max-width:767px)";
$vpmmobile: "(max-width:575px)";
$vpsmmobile: "(max-width:400px)";
$vpxsmobile: "(max-width:359px)";
$vpSmdesktop: "(max-width:1500px)";
$vptabletPro: "(max-width:1023px)";
$vptabletPro1: "(max-width:1024px)";
$vpxsmobileSmall: "(max-width:389px)";
$height700:"(max-height:669px)";
$height600:"(max-height:600px)";
$height800:"(max-height:800px)";
$vp-min-mobile: "(min-width:768px)";
$lgdesktopSmall2: "(max-width:1200px)";
