.our-aim-wrapper{  
    background-color: $grayssty6;
    .bg-img-wrapper{
        position: relative;
        &::before{
            content: " ";
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 14%;
            background-position: 0;
            background-image: url(../img/background/bg-aim.png);
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 0;
            /*rtl:raw:
                transform: scale(-1);
            */
            @media #{$vptabletP}{
                width: 124px;
                height: 242px;
            } 

        }
    }
    .our-aim-heading{  
        max-width: 900px;
        /*rtl:raw:max-width: 914px;*/
        width: 100%;  
        /*rtl:begin:ignore*/
        padding-bottom: 30px;
        /*rtl:end:ignore*/ 
        /*rtl:raw:padding-bottom: 60px;*/
        position: relative;
        z-index: 1;
        @media #{$vpmobile}{ 
            padding-bottom: 25px;
        }  
        h2{
            font-weight: bold;            
                                  
            span{
            position: relative;   
            white-space: nowrap;         
                &::after{                
                position: absolute;
                left: 0;
                bottom: -4px;
                /*rtl:raw:
                    bottom: 0px;
                */
                display: inline-block;
                content: "";
                border-bottom: 5px solid $magenta;
                width: 100%; 
                    @media #{$vpmobile}{
                        border-bottom: 0;
                    }                              
                }
                @media #{$vpmobile}{
                    margin-left: 0;
                    white-space: normal;
                }
            }
        }
        .heading6-bold{
            font-size: 24px;
            font-weight: bold;
            color: $darkgray;
        }
    }
    .sec-aims-wrapper{
        margin: 0 -20px;
        position: relative;
        z-index: 1;
        // 1919
        @media #{$vpdesktopSmall}{
            // margin: 0 0px;
            margin: 0 -30px
        }
        // 1400
        @media #{$vpdesktop}{
            margin: 0 0px;
        }
        // 911
        @media #{$vptabletP}{
            margin: 0 -15px
        }
        // 768
        @media #{$vpmobile}{
            margin: 0 0px;
        }
        .sec-aims{
            margin-bottom: 0px;
            padding: 0 20px;
            // 1919
            @media #{$vpdesktopSmall}{
                // margin: 0 0px;

                padding: 0 30px; 
            }
            // 1400
            @media #{$vpdesktop}{
                // margin: 0 0px;
                
            }
            // 911
            @media #{$vptabletP}{
                // margin: 0 0px;
                padding: 0 15px;
            }
            // 768
            @media #{$vpmobile}{
                margin-bottom: 60px;
                padding: 0 0px;
                margin-bottom: 20px;
            }
            .heading6-bold{
                font-size: 24px;
                line-height: 24px;
                font-weight: bold;
                color: $darkgray;
                margin-bottom: 0;
            }
            p{
                width: 100%;
                @media #{$vpmobile}{
                    max-width:100%;
                    width: 100%;
                }
            }
            .para{
                position: relative;
                padding-bottom: 12px;
                margin-bottom: 12px;
                @media #{$vpmobile}{
                    padding-bottom: 15px;
                    margin-bottom: 15px;
                }
                font-weight: 600;
                &::after{                
                display: inline-block;
                content: "";
                border: 1px solid $magenta;
                width: 100%; 
                position: absolute;               
                left: 0;
                bottom: 0;
                }
            }
            .sec-aim-icon{
                height: 45px;
                width: 45px;
                display: flex;
                align-items: flex-end;
                @media #{$vpmobile}{
                    height: 40px;
                    width: 40px;
                }
                img,svg{
                    width: 100%;
                }
            }
        }
    }
    .sec-aim-title{
        margin-top: 30px;
    }
    
}
