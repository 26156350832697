.register-wrapper {
    overflow: hidden;
    .bg-img-wrapper {
        background-color: $grayssty6;
        position: relative;
        &::before{
            content: " ";
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 14%;
            background-position: 0;
            background-image: url(../img/background/bg-aim.png);
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 0;
            /*rtl:raw:
                transform: scale(-1);
            */
            @media #{$vptabletP}{
                width: 124px;
                height: 242px;
            }

        }
    }

    .register-heading {
        padding-bottom: 30px;
        max-width: 849px;
        position: relative;
        z-index: 1;
        @media #{$vptabletL} {
            padding-bottom: 35px;
        }
        @media #{$vpmobile} {
            padding-bottom: 20px;
            max-width: 90%;
        }

        .heading2 {
            padding-bottom: 15px;

            @media #{$vpmobile} {
                padding-bottom: 24px;
            }
        }
    }

    .register-slider {
        margin: 0 -25px;
        position: relative;
        z-index: 1;
        @media #{$lgdesktop} {
            margin: 0 -15px;
        }
        @media #{$lgdesktopSmall2} {
            margin: 0 -10px;
        }
        @media #{$vpmobile} {
            margin: 0 -10px;
        }
        .slick-list {
            @media #{$vpmobile} {
                overflow: visible;
                width: 100%;
                max-width: 90%;
            }
        }
        .slide-card {
            max-width: 33.334%;
            padding: 0 25px;
            @media #{$lgdesktop} {
                padding: 0 15px;
            }
            @media #{$lgdesktopSmall2} {
                padding: 0 10px;
            }
            @media #{$vpmobile} {
                padding: 0 10px;
            }
            .sec-register {
                border-radius: 8px;
                padding: 98px 57px 39px 33px;
                height: 100%;
                @media #{$lgdesktop} {
                    padding: 70px 33px 30px;
                }
                @media #{$lgdesktopSmall2} {
                    padding: 55px 20px 20px 20px;
                }

                @media #{$vpmobile} {
                    padding: 44px 43px 23px 16px;
                }

                .para {
                    position: relative;
                    color: $white;
                    font-weight: bold;

                    &::after {
                        position: absolute;
                        content: "";
                        border: 1px solid $white;
                        width: 100%;
                        max-width: 200px;
                        bottom: -19px;
                        left: 0;

                        @media #{$vpmobile} {
                            bottom: -17px;
                        }
                    }
                }

                .sec-register-icon {

                    img,
                    svg {
                        height: 60px;
                        width: 60px;
                        @media #{$lgdesktop} {
                            height: 50px;
                            width: 50px;
                        }
                        @media #{$lgdesktopSmall2}{
                            height: 40px;
                            width: 40px;
                        }
                        @media #{$vpmobile} {
                            height: 42px;
                        width: 42px;
                        }
                    }
                }

                .sec-register-txt {
                    padding-top: 32px;

                    @media #{$lgdesktop} {
                        padding-top: 11px;
                    }
                    
                    @media #{$lgdesktopSmall2} {
                        padding-top: 11px;
                    }
                    @media #{$vpmobile} {
                        padding-top: 30px;
                    }

                    .sub-para {
                        color: $white;
                        padding-top: 28px;

                        @media #{$vpmobile} {
                            padding-top: 15px;
                        }
                    }
                }
            }
        }
        .slick-track{
            display: flex !important;
        }
        .slick-slide{
            height: inherit !important;
        }
    }

}

