.footer {
    background-color: $black;
    position: relative;
    padding: 38px 60px;
    @media #{$vpdesktop} {
        padding: 35px 36px;
    }
    // max-width 991px
    @media #{$vptabletP} {
        padding: 20px 20px;
    }
    @media #{$vpmobile} {
        padding: 20px 20px;
    }
    &::after {
        position: absolute;
        content: "";
        background-image: url(../img/background/bg-footer.png);
        background-repeat: no-repeat;
        height: 100%;
        width: 300px;
        top: 0;
        right: 0;
        background-size: cover;
        background-position: center right;
        /*rtl:raw:transform: rotatey(180deg);*/
        @media #{$vptabletP} {
            background-image: url(../img/background/bg-footer-mobile.png);
            width: 141px;
            background-size: cover;
        }
    }
    .footer-head {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        // padding: 74px 60px 137px;
        padding: 0px 0px 150px;
        @media #{$vptabletL} {
            .footer-logo {
                width: 100%;
            }
        }
        @media #{$vpdesktop} {
            padding: 0px 0px 140px;
        }
        @media #{$vptabletP} {
            flex-direction: column;
            align-items: left;
            align-items: flex-start;
            padding-bottom: 0px;
            // padding: 20px 20px 0px;
            padding: 0px 00px 0px;
            .footer-logo {
                width: 100%;
                max-width: 320px;
                align-items: left;
            }
        }
        @media #{$vpmmobile} {
            // padding: 20px 20px 0px;
            padding: 00px 0px 0px;
            align-items: flex-start;
            .footer-logo {
                width: 100%;
                max-width: 320px;
            }
        }

        .footer-logo-wrapper{
            flex: 0 0 282px;
			max-width: 282px;
            // @media #{$vptabletPro1} {
            //     flex: 0 0 25.7%;
            //     max-width: 25.7%;
            // }
            // @media #{$vptabletP} {
            //     flex: 0 0 69%;
            //     max-width: 239px
            // }
            @media #{$vpdesktop} {
                flex: 0 0 255px;
                max-width: 255px;
            }
            @media #{$vptabletP} {
                flex: 0 0 69%;
			    max-width: 239px;
            }
            @media #{$vpxsmobileSmall} {
                flex: 0 0 69%;
			    max-width: 69%;
            }
            img{
                width: 100%;
                height: auto;
            }
        }
        .ft-nav {
            display: flex;
            flex-direction: row;
            padding-bottom: 10px;
            padding-right: 0px;
            .ft-nav-item {
                padding: 0 36px;
                @media #{$vpMddesktop} {
                    padding: 0 31px;
                }
                @media #{$lgdesktop} {
                    padding: 0 26px;
                }
                @media #{$vptabletPro1}{
                    padding: 0 14px;
                }
                @media #{$vpdesktop} {
                    padding: 0 14px;
                }
                &:last-child {
                    padding-right: 0;
                }

                &:first-child {
                    padding-left: 0;
                    padding-top: 0;
                }
                .ft-nav-link {
                    color: $grayssty5;
                    transition: all 0.35s ease 0s;
                    font-weight: bold;
                    transition: color 0.25s ease-in-out;

                    @media #{$lgdesktop} {
                        // font-size: 18px;
                    }
                    @media #{$vpdesktop} {
                        // font-size: 16px;
                    }
                    @media #{$vptabletL} {
                        // font-size: 14px;
                    }
                    &:hover{
                        color: $beige;
                        
                    }
                }
            }
            @media #{$vptabletP} {
                flex-direction: column;
                width: 100%;
                padding: 50px 0;
                .ft-nav-item {
                    padding: 13px 0;
                    &:last-child{
                        padding: 13px 0 0 0;
                    }
                }
            }
        }
    }
    .footer-bottom {
        position: relative;
        z-index: 1;
        // padding: 60px 60px 60px 60px;
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $white;
        // @media #{$vptabletL} {
        //     padding: 40px 74px;
        // }
        @media #{$vpdesktop} {
            // padding: 40px 36px 140px 36px;
            // padding: 40px 36px 40px 36px;
            padding: 0px 0px 0px 0px;
        }
        @media #{$vptabletP} {
            // padding: 0 20px 20px 20px;
            padding: 0 0px 0px 0px;
            flex-direction: column-reverse;
        }
        .para-sm{
            max-width: 100%;
            @media #{$vptabletP} {
                text-align: center;
                max-width: 328px;
            }
        }
        .copy-right {
            color: $white;
        }
        .fb-nav {
            flex-direction: row;
            display: flex;
            flex-wrap: wrap;
            @media #{$vptabletP} {
                padding-bottom: 10px;
                justify-content: center;
            }
            .fb-nav-item {
                padding: 0 15px;
                position: relative;
                @media #{$vpdesktop} {
                    padding: 0 12px;
                }
                @media #{$vpxsmobileSmall} {
                    padding: 0 10px;
                }
                &::after {
                    content: '';
                    position: absolute;
                    /*rtl:begin:ignore*/
                    height: 100%;
                    /*rtl:end:ignore*/
                    /*rtl:raw:height: 83%;*/
                    width: 2px;
                    background-color: $grayssty5;
                    left: 0px;
                    top: 50%;
                    transform: translateY(-50%);
                    /*rtl:raw:top: 0;*/
                    /*rtl:raw:transform: translateY(0%);*/


                    @media #{$vpdesktop} {
                        // left: -12px;
                    }

                    @media #{$vptabletP} {
                        // height: 13px;
                        // left: -11px;
                        height: 70%;
                    }
                }
                &:last-child {
                    padding-right: 0;
                }
                &:first-child {
                    padding-left: 0;
                        &::after {
                            width: 0;
                        }
                }
                .fb-nav-link {
                    color: $grayssty5;
                    position: relative;
                    // font-size: 20px;
                    transition: color 0.25s ease-in-out;
                    @media #{$vpdesktop} {
                        // font-size: 18px;
                    }

                    @media #{$vptabletL} {
                        // font-size: 16px;
                    }
                    @media #{$vpsmmobile} {
                        // font-size: 12px;
                    }
                    &:hover{
                        color: $beige;
                    }
                    
                }
            }
        }
    }
}
.span-text-bold{
    color: $magenta;
}