.form-wrapper{
    position: relative;
    background-color: $bgFormWrapper;

    .bg-form-pattren{
        position: absolute;
        left: 0;
        top: 0;
        transform: translateX(-50%);
        z-index: 0;
        max-width: 850px;
        width: 100%;
        height: 100%;
        max-height: 710px;
        img,svg{
            object-fit: contain;
            height: 100%;
            width: 100%;
        }
    }
}
.thankyou-wrapper-style{
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form-steps-wrapper{
    // padding: 70px 0;
    position: relative;
    z-index: 1;
    display: none;
    justify-content: center;
    flex-direction: column;
    padding: 5vh 0px;
    &.step--active{
        display: flex;
    }
    @media #{$vpmobile}{
        // padding: 40px 0;
    }
    // &.form-steps-wrapper-flex{
    //     display: flex;
    //     justify-content: space-between;
    //     flex-direction: column;

    // }
}
.craft-heading {
    margin-bottom: 5vh;
    position: relative;
    @media #{$vp-min-mobile} {
        @media #{$height700} {
            margin-bottom: 2vh;
        }
    }
    &.basic-craft-heading{
        margin-bottom: 4vh;
    }
    .back-to-btn{
        color: $darkgray;
        position: absolute;
        /*rtl:begin:ignore*/
        left: 0;
        transition: all 0.35s linear;
        /*rtl:end:ignore*/
        font-family: 'Effra';
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        border: 0;
        padding: 0;
        
        // border-bottom: 2px solid transparent;
        margin-bottom: 0;
        @media #{$vpmobile} {
            position: relative;
            width: 100%;
            margin-top: 20px;
        }
        &:focus{
            outline: none;
            border: 0;
        }
        &:hover{
            color: $magenta;
            border-color: $magenta;
            img,svg{
                /*rtl:begin:ignore*/
                margin-right: 10px;
                /*rtl:end:ignore*/
                path,
                rect,
                polygon,
                line {
                    stroke: $magenta;
                }
            }
        }
        img,svg{
            transform: scale(-1);
            /*rtl:begin:ignore*/
            margin-right: 15px;
            transition: margin-right 0.25s linear;
            /*rtl:end:ignore*/
            width: 16px;
            @media #{$vpmobile}{
                /*rtl:begin:ignore*/
                margin-right: 8px;
                /*rtl:end:ignore*/
                width: 14px;  
            }

            path,
            rect,
            polygon,
            line {
                stroke: $darkgray;
            }
        }
    }
    p {
        text-align: center;
        color: $magenta;
        font-weight: bold;
         /*rtl:begin:ignore*/
        margin-bottom: 0;
        /*rtl:end:ignore*/
        /*rtl:raw:margin-bottom: 10px;*/
    }

    h2 {
        text-align: center;
        font-weight: bold;
        color: $darkgray;
        margin-bottom: 0;
    }
}
.form-btn-wrapper{
    padding: 0;
    margin-top: 6.2vh;
    &.form-btn-wrapper-disclamer-spacing{
        margin-top: 38px;
    }
    @media #{$vp-min-mobile} {
        @media #{$height700} {
            margin-top: 3.2vh;
        }
    }
    @media #{$vpmobile}{
        padding: 0;
    }
    .form-btn-warp{
        display: flex;
        justify-content: center;
        align-items: center;
        
        a,button{
            margin: 0 15px;
        }
    }
}
.back-form-btn{
    background-color: transparent;
    border: 0;
    padding: 0;
    color: $gray;
    transition: all 0.35s ease 0s;
    border-bottom: 2px solid transparent;
    &:focus{
        outline: none;
        border:none;
    }
    &:hover{
        color: $magenta;
        border-color: $magenta;
    }
}
// Choose Type Style
.craft-sec,.craft-sec-2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media #{$vpmobile}{
        flex-direction: column;
        align-items: center;
    }
    .craft-button {
        display: flex;
        justify-content: center;
        margin: 0 -17px;
        flex-wrap: wrap;

        @media #{$vpmobile} {
            // display: block;
            flex-direction: column;
            margin: 0 0px;
            width: 100%;
        }

        
    }

}
// Basic Detail Form Style
.basic-detail-wrapper{
    .rounded-box{
        padding: 50px 110px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        margin: 15px auto;
        border-radius: 8px;
        margin-bottom: 65px;
        margin-top: 0;
        background-color: $white;
        &.rounded-box-disclamer-spacing{
            margin-bottom: 19px;
        }

        @media #{$lgdesktop} {
            padding: 50px;
        }
        @media #{$vpmobile}{
            padding: 35px 20px;
        }
        .form-heading{
            margin-bottom: 25px;
            @media #{$vptabletP}{
                margin-bottom: 38px;
            }
            .heading4{           
                font-weight: bold;
                color: $darkgray;
                span{
                    font-family: 'Harir';
                }
            }
        }
        .form-title{
            .sub-link{                    
                font-weight: bold;
                color: $magenta;
            }
        }   
        /*rtl:begin:ignore*/ 
        .title-sec{        
            margin-bottom: 25px;
            display: block;
            @media #{$vptabletP}{
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                max-width: 390px;
            }
        }
        /*rtl:end:ignore*/
        .details-sec{
            display: flex;
            justify-content: flex-start;
            margin: 0 -30px;
            @media #{$lgdesktop} {
                margin: 0 -15px;
            }
            @media #{$vptabletP} {
                margin: 0 0px;
                flex-direction: column;
            }
            &.details-sec-front-back-upload{
                .input-container-attached{
                    flex: 0 0 30%;
                    max-width: 30%;                
                    @media #{$lgdesktop} {
                        flex: 0 0 33.33%;
                        max-width: 33.33%;
                        padding: 0 15px; 
                    }
                    @media #{$vptabletP}{
                        flex: 0 0 100%;
                        max-width: 100%;            
                        padding: 0 0px;
                    }    
                } 
            }
            // padding: 15px 0;
            .input-container{
                flex: 0 0 30%;
                max-width: 30%;            
                position: relative;
                padding: 0 30px;
                @media #{$lgdesktop} {
                    flex: 0 0 33.33%;
                    max-width: 33.33%;
                    padding: 0 15px; 
                }
                @media #{$vptabletP}{
                    flex: 0 0 100%;
                    max-width: 100%;            
                    padding: 0 0px;
                }  
                &.city-container-styling{
                    opacity: 0.6;
                    pointer-events: none;
                }          
            }
            .input-container-attached{
                flex: 0 0 60%;
                max-width: 60%;
                padding: 0 30px; 
                @media #{$lgdesktop} {
                    flex: 0 0 66.66%;
                    max-width: 66.66%;
                    padding: 0 15px; 
                }
                @media #{$vptabletP}{
                    flex: 0 0 100%;
                    max-width: 100%;            
                    padding: 0 0px;
                }
            }
                        
        } 
    }
}

// Thank You Style
.thankyou-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    .heading-sec{
        
        h2{
            font-weight: bold;
            text-align: center;
            color: $darkgray;
        }
    }
    .para-sec{
        max-width: 700px;
        p{
            text-align: center;
        }
    }
    .img-sec{
        width: 280px;
        height: 280px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        margin: 3vh 0 0 0;
        @media #{$vpmobile} {
            width: 200px;
            height: 200px;
        }
        img{           
            border-radius: 50%; 
        }
    }
}
.arbic-link-form{
    position: absolute;
    right: 0;
    width:100%;
    left: auto;
    top: 0px;
    padding: 20px 60px;
    text-align: right;
    z-index: 2;
    @media #{$vpdesktop} {
        padding: 20px 36px;
    }
    // max-width 991px
    @media #{$vptabletP} {
        padding: 15px 20px;
    }
    @media #{$vpmobile} {
        padding: 15px 20px;
    }
    @media #{$vpmobile} {
        display: none;
    }
    .sub-para{
        color: $magenta;
        font-weight: bold;
        transition:color 0.3s ease;
        &:hover{
            color: $beige;
        }
    }
}
.emirate-p{
    &.invalid-field{
        p{
            color: $errrorClr;    
        }
    }
    p{
        font-size: 17px;
        color:$magenta;
        font-weight: 600;
        position: absolute;
        top: -11px;
        margin: 0;
    }
}